import React from 'react';
import styled from 'styled-components';

interface Placement {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}

interface Props {
    onClick: () => void;
    text?: string;
    className?: string;
    size: number;
    placement?: Placement;
}

const Bubble: React.FC<Props> = ({
    onClick,
    text = '',
    size,
    className = '',
    placement,
}) => {
    const textParts = text.split(' ');
    const newText = textParts.reduce((acc, cur) => {
        if (!acc) {
            if (cur.length > 14) return `${cur.substring(0, 14)}-${cur.substring(14)}`;
            return cur;
        }
        if (cur.length > 14) return `${acc} ${cur.substring(0, 14)}-${cur.substring(14)}`;

        return `${acc} ${cur}`;
    }, '');
    const StyledButton = styled.button`
        &:after {
            content: "${newText}";
            position: absolute;
            white-space: pre-wrap;
            max-width: 128px;
            margin: 0 auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            text-align: center;
          };
    `;
    return (
        <StyledButton
            className={ `p-3 rounded-full hover:opacity-75 focus:outline-none ${placement ? 'absolute' : 'relative'} ${className}` }
            type="button"
            onClick={ onClick }
            style={ {
                padding: (60 + (size * 40)),
                fontSize: (16 + (size * 3)),
                // eslint-disable-next-line
                ...(placement ? placement : {}),
            } }
        />
    );
};

export default Bubble;

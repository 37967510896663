import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { editBubbleColorOrder } from '../constants/global';

import Search from '../components/Search';
import Loader from '../components/Loader';
import SelectedBubble from '../components/SelectedBubble';
import { AppState } from '../redux/types/app';
import { UserState } from '../redux/types/user';
import { BubbleState } from '../redux/types/bubble';

import { setBackground, setShowNav } from '../redux/actions/app';
import {
    leaveBubble,
    getBubbles,
    createBubble,
    joinBubble,
} from '../redux/actions/user';
import { searchBubbles, searchBubblesSuccess } from '../redux/actions/bubble';

interface Props {
    setBackgroundFunc: (background: AppState['backgroundColor']) => void;
    leaveBubbleFunc: (pk: string | undefined, id: number) => void;
    createBubbleFunc: (name: string) => void;
    joinBubbleFunc: (name: string) => void;
    searchBubblesFunc: (id: string) => void;
    setShowNavFunc: (show: boolean) => void;
    resetSuggestionsFunc: () => void;
    getBubblesFunc: () => void;
    app: AppState;
    user: UserState;
    bubble: BubbleState;
}

const EditBubbles: React.FC<Props> = ({
    setBackgroundFunc,
    leaveBubbleFunc,
    createBubbleFunc,
    joinBubbleFunc,
    getBubblesFunc,
    searchBubblesFunc,
    setShowNavFunc,
    resetSuggestionsFunc,
    app,
    user,
    bubble,
}) => {
    const { t } = useTranslation();
    const { backgroundColor, showNav } = app;
    const { bubbles: userBubbles, loading: userLoading, error: userError } = user;
    const { searchResults, loading: bubbleLoading } = bubble;

    useEffect(() => {
        if (backgroundColor !== 'bg-p-2') setBackgroundFunc('bg-p-2');
        if (!userBubbles || !userBubbles.length) getBubblesFunc();
        if (userBubbles.length < 3) {
            setShowNavFunc(false);
        } else if (!showNav) {
            setShowNavFunc(true);
        }
        searchBubblesFunc('');
        // eslint-disable-next-line
    }, [userBubbles]);

    const renderBubbles = () => {
        if (userBubbles && userBubbles.length) {
            return userBubbles.map(({ name, id, user_has_bubble_pk }, index) => (
                <SelectedBubble onClose={ () => leaveBubbleFunc(user_has_bubble_pk, id) } className={ editBubbleColorOrder[index] } name={ name } key={ index } />
            ));
        }
        return null;
    };
    const renderAddMoreText = () => {
        if (userBubbles && userBubbles.length < 3) return <div className="text-gray-400 text-center opacity-75 mb-40">{ t('bubbles.addMore') }</div>;
        return null;
    };

    return (
        <>
            <Search
                onResetSuggestions={ resetSuggestionsFunc }
                onSuggestionClick={ (name: string) => joinBubbleFunc(name) }
                suggestions={ searchResults }
                loading={ bubbleLoading }
                onNoResultsClick={ (name: string) => createBubbleFunc(name) }
                onChange={ (data: string) => searchBubblesFunc(data) }
            />
            <div className="pl-3 mt-8 mb-6 container">
                <h2 className="text-xl">
                    { t('bubbles.selected') }
                </h2>
                <span className="text-xs text-gray-400">
                    { t('bubbles.amountSelected', { count: userBubbles.length }) }
                </span>
            </div>
            <div className="relative container">
                { userLoading && <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"><Loader className="z-10" /></div>}
                <div className={ `px-3 ${userLoading && 'opacity-75'}` }>
                    { renderBubbles() }
                </div>
            </div>
            <div className="justify-end flex flex-grow flex-col md:flex-grow-0">
                { userError && <span className="container text-center text-red-500 block mb-4">{ userError }</span> }
                { renderAddMoreText() }
                {/* <button
                    className={ `btn bg-g-1 container uppercase ${userLoading && 'opacity-75'}` }
                    type="submit"
                    disabled={ userLoading }
                >
                    { t('bubbles.procceed') }
                </button> */}
            </div>
        </>
    );
};

interface State {
    app: AppState;
    user: UserState;
    bubble: BubbleState;
}

const mapStateToProps = ({ app, user, bubble }: State) => ({
    app,
    user,
    bubble,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    leaveBubbleFunc: (pk: string | undefined, id: number) => dispatch(leaveBubble(pk, id)),
    getBubblesFunc: () => dispatch(getBubbles()),
    searchBubblesFunc: (query: string) => dispatch(searchBubbles(query)),
    createBubbleFunc: (name: string) => dispatch(createBubble(name)),
    joinBubbleFunc: (name: string) => dispatch(joinBubble(name)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
    resetSuggestionsFunc: () => dispatch(searchBubblesSuccess([])),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBubbles);

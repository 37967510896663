import Placeholder from '../svg/user-placeholder.jpg';

export const showDate = (dateString: string): string => {
    if (!dateString) return '';
    const d = new Date(dateString);
    const now = new Date();

    const dayInSeconds = 86400000;
    // @ts-ignore
    if ((now - d) > dayInSeconds) return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear().toString().slice(-2)}`;
    return `${d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`}: ${d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`}`;
};

export const validateImage = (imageUrl: string): string => {
    const img = new Image();
    try {
        img.onload = () => imageUrl;
        img.src = imageUrl;
    } catch (err) {
        return Placeholder;
    }
    return Placeholder;
};

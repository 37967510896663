import { ChatState, ChatTypes } from '../types/chat';
import { loginSuccess } from '../types/user';
import {
    SET_ACTIVE_CHAT,
    SEND_MESSAGE,
    ADD_MESSAGE_NOTIFICATION,
    REMOVE_MESSAGE_NOTIFICATION,
    ADD_RECENT_CONVO_NOTIFICATIONS,
} from '../constants/chat';
import { LOGIN_SUCCESS } from '../constants/user';

const initialState: ChatState = {
    active: {
        pk: 0,
        email: '',
        name: '',
        picture: {
            id: 0,
            url: '',
        },
    },
    loading: false,
    error: '',
    notifications: [],
};

const chat = (state = initialState, action: ChatTypes | loginSuccess): ChatState => {
    switch (action.type) {
        case SEND_MESSAGE:
            return { ...state, error: '', loading: true };
        case LOGIN_SUCCESS:
        case ADD_RECENT_CONVO_NOTIFICATIONS:
            const newNotifications = [...action.notifications].filter(({ sender_id, bubble_id }) => !state.notifications.find((noti) => noti.bubble_id === bubble_id && noti.sender_id === sender_id));
            return { ...state, notifications: [...state.notifications, ...newNotifications] };
        case ADD_MESSAGE_NOTIFICATION:
            return { ...state, notifications: [...state.notifications, action.message] };
        case REMOVE_MESSAGE_NOTIFICATION:
            return { ...state, notifications: state.notifications.filter(({ sender_id, bubble_id }) => sender_id !== action.sender_id || bubble_id !== action.bubble_id) };
        case SET_ACTIVE_CHAT:
            return { ...state, active: action.member };
        default:
            return state;
    }
};

export default chat;

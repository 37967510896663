import { SET_BACKGROUND, SET_SHOWNAV, SET_SHOWMODAL } from '../constants/app';
import { AppTypes, option as OptionType } from '../types/app';

export const setBackground = (background: string): AppTypes => ({
    type: SET_BACKGROUND,
    background,
});

export const setShowNav = (show: boolean): AppTypes => ({
    type: SET_SHOWNAV,
    show,
});

export const setShowModal = (show: boolean, option: OptionType): AppTypes => ({
    type: SET_SHOWMODAL,
    show,
    option,
});

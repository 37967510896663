import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
const resources = {
    nl: {
        translation: {
            error: {
                unauthorized: 'Combinatie van email en wachtwoord komt niet overeen',
                general: 'Er is iets fout gegaan... Probeer opnieuw.',
                exists: 'Er bestaat al een account met dit email adres.',
                contact: 'Er is iets fout gegaan... neem contact op met de beheerder.',
                unvalidResetLink: 'De huidige token is niet geldig.',
                dismiss: 'Sluiten',
                notFound: 'We hebben de pagina niet kunnen vinden',
                goback: 'Ga terug',
            },
            general: {
                terms: 'Algemene voorwaarden',
                privacy: 'Privacybeleid',
                and: 'en',
                termsAgree: 'Door in te loggen gaat u akkoord met onze',
                add: 'Toevoegen',
                expired: 'Sessie verlopen',
                success: 'Gelukt!',
                search: 'Zoeken...',
            },
            user: {
                info: 'Info',
                verified: 'U bent succesvol geregistreerd',
                loggedIn: 'U bent ingelogd!',
                loggedOut: 'U bent uitgelogd!',
                register: 'Account aanmaken',
                login: 'Inloggen',
                email: 'E-mail',
                password: 'Wachtwoord',
                currentPassword: 'Huidige Wachtwoord',
                newPassword: 'Nieuwe Wachtwoord',
                confirm: 'Herhaal Wachtwoord',
                name: 'Naam',
                account: 'Account',
                registeredSuccess: 'Gelukt! Maak uw registratie compleet door te klikken op de link die u heeft ontvangen in de mail',
                registeredAlready: 'Heeft u al een account',
            },
            form: {
                email: {
                    required: 'Uw e-mail is verplicht',
                    valid: 'Vul een geldig email adres in',
                },
                password: {
                    required: 'Uw wachtwoord is verplicht',
                    minimumCharacters: 'Uw wachtwoord moet minimaal {{count}} tekens bevatten',
                },
                name: {
                    required: 'Uw naam is verplicht',
                    minimumCharacters: 'Uw naam moet minimaal {{count}} tekens bevatten',
                },
            },
            navigation: {
                chats: 'Chats',
                myBubbls: 'Mijn bubbls',
                hub: 'Hub',
                account: 'Account',
                bubbls: 'Bubbls',
            },
            button: {
                login: 'INLOGGEN',
                create: 'AANMAKEN',
                add: 'TOEVOEGEN',
                register: 'REGISTREREN',
                forgotPassword: 'WACHTWOORD VERGETEN?',
                send: 'VERZENDEN',
                save: 'opslaan',
            },
            bubbles: {
                selected: 'Geselecteerd',
                procceed: 'verder',
                add: 'Toevoegen',
                bubbls: 'Bubbls',
                members: 'Leden',
                mensen: 'Mensen',
                addMore: 'Voeg meer bubbls toe om verder te gaan',
                likes: 'Likes',
                insights: 'ups',
                noMembers: 'U bent het enige lid',
                empty: 'Deze bubble heeft nog geen ups',
                amountSelected: '{{count}} bubbls geselecteerd',
            },
            insights: {
                add: 'Ups toevoegen',
                required: 'Dit veld is verplicht',
                max3: 'Je kunt max 3 toevoegen.',
                placeholder: 'Nieuwe Up toevoegen...',
                example1: 'Meer melden',
                example2: 'Veiligheid in voorbereiding',
                example3: 'Ondernemer betrekken bij projecten',
            },
            chat: {
                start: 'Verstuur je eerste berichtje naar {{name}}',
                type: 'Type een bericht',
                recent: 'Recente berichten',
            },
            account: {
                data: 'gegevens',
                save: 'opslaan',
                reset: 'Wachtwoord wijzigen',
                overige: 'overige',
                terms: 'Algemene voorwaarden',
                privacy: 'Privacybeleid',
                logout: 'Uitloggen',
                passChanged: 'Wachtwoord succesvol veranderd',
                delete: 'Account verwijderen',
                samePass: 'Wachtwoord kan niet hetzelfde zijn',
                mismatch: 'Nieuwe wachtwoorden komen niet overeen',
                forgot: 'Nadat u op verzenden klikt ontvangt u een e-mail waarmee u een nieuw wachtwoord kan aanmaken.',
            },
            page: {
                titles: {
                    welcome: 'WELKOM',
                    info: 'INFO',
                    register: 'ACCOUNT AANMAKEN',
                    login: 'INLOGGEN',
                    account: 'ACCOUNT',
                    my: 'MIJN BUBBLS',
                    hub: 'HUB',
                    chats: 'CHATS',
                    reset: 'WACHTWOORD VERGETEN',
                },
            },
            terms: {
              intro: 'Deze gebruiksvoorwaarden zijn van toepassing op het gebruik van de Bubblhub-app (hierna: “de App”). De App wordt u aangeboden door Bubblhub B.V.',
              article_1: {
                title: 'Artikel 1 Toepasselijkheid',
                content: {
                  item_1: 'In deze voorwaarden worden de voorts volgende begrippen gebruikt: Gebruiker: person(en) die onder de organisatie gebruik maken van de App en gegevens verstrekken. Gebruikersvoorwaarden: deze gebruikersvoorwaarden die tevens zijn te raadplegen op www.bubblhub.nl onder het kopje Privacy & Algemene voorwaarden.',
                  item_2: 'Door in te loggen in de App aanvaardt de Gebruiker deze Gebruiksvoorwaarden.',
                }
              },
              article_2: {
                title: 'Artikel 2 Gebruik',
                content: {
                  item_1: 'De Gebruiker dient een account aan te maken met sterk wachtwoord om in te loggen',
                  item_2: 'De Gebruiker dient voldoende maatregelen te treffen om misbruik of gebruik door derden van zijn/haar account tegen te gaan en de inloggegevens strikt geheim te houden. Het is de Gebruiker niet toegestaan de inloggegevens aan derden te verstrekken. Gebruik van de App door onbevoegden is niet toegestaan.',
                  item_3: 'Indien de Gebruiker jonger is dan 18 jaar, dient hij/zij voor het gebruik van de App de toestemming van zijn/haar ouder(s) of wettelijk vertegenwoordiger(s) te hebben. Door deze Gebruiksvoorwaarden te aanvaarden, garandeert de Gebruiker dat hij/zij 18, jaar of ouder is of toestemming heeft van zijn/haar ouder(s) of wettelijk vertegenwoordiger(s).',
                  item_4: 'De Gebruiker krijgt een niet-exclusief, niet overdraagbaar, niet-sublicentieerbaar, persoonlijk en herroepelijk recht om de App te gebruiken op een smartphone of ander draagbaar apparaat.',
                }
              },
              article_3: {
                title: 'Artikel 3 Misbruik',
                content: {
                  item_1: 'Het gebruik van de App is niet toegestaan voor handelingen of gedragingen die in strijd zijn met wet- en regelgeving, openbare orde en goede zeden. Meer in het bijzonder is het niet toegestaan onfatsoenlijke, immorele of discriminerende uitingen te verspreiden via de App.',
                  item_2: {
                    title: 'Het is de Gebruiker voorts niet toegestaan de App:',
                    content: {
                      item_1: 'te deassembleren, decompileren of onderwerpen aan ‘reverse engineering’;',
                      item_2: 'aan te sluiten op een netwerk waardoor andere apparaten van de App gebruik kunnen maken;',
                      item_3: 'op welke wijze dan ook te distribueren en/of aan een derde beschikbaar te stellen;',
                      item_4: 'te gebruiken voor het overmatig verzenden van gegevens of berichten, verspreiden van virussen en andere schadelijke software;',
                      item_5: 'te gebruiken voor commerciële doeleinden;',
                      item_6: 'te gebruiken op een wijze die nadelig of schadelijk kan zijn voor de werkgever van de Gebruiker of voor andere gebruikers.',
                    }
                  },
                }
              },
              article_4: {
                title: 'Artikel 4 Gegevensverwerking en privacy',
                content: {
                  item_1: 'Bubblhub verwerkt verschillende persoonsgegevens. De verwerking, opslag en het gebruik van deze gegevens worden beschreven in de privacy voorwaarden.',
                }
              },
              article_5: {
                title: 'Artikel 5 Onderhoud/aanpassingen',
                content: {
                  item_1: 'Bubblhub B.V. onderhoudt de App en kan updates uitbrengen om aanpassingen aan te brengen, nieuwe functionaliteiten toe te voegen of prestaties te verbeteren.',
                  item_2: 'Bubblhub B.V. heeft voorts het recht om informatie op de App te wijzigen of te verwijderen.'
                }
              },
              article_6: {
                title: 'Artikel 6 Beëindiging gebruiksrecht',
                content: {
                  item_1: 'De Gebruiker heeft een gebruiksrecht voor onbepaalde duur en kan het gebruik van de App op ieder moment beëindigen door het account te verwijderen.',
                  item_2: 'Bubblhub B.V. kan het gebruiksrecht van de App ten aanzien van één of meer Gebruikers - met onmiddellijke ingang en zonder verplicht te zijn om enige vergoeding te betalen - beëindigen in geval van misbruik van de App, gebruik van de App in strijd met deze Gebruikersvoorwaarden en/of indien Bubblhub B.V. dan wel de werkgever van de gebruiker hinder of schade ondervindt, zulks uitsluitend naar haar oordeel.',
                }
              },
              article_7: {
                title: 'Artikel 7 Intellectuele eigendomsrechten',
                content: {
                  item_1: 'Alle intellectuele eigendomsrechten op (de inhoud van) de App, waaronder de onderliggende software, teksten, grafisch materiaal, logo’s, afbeeldingen etc, berusten bij Bubblhub B.V.',
                  item_2: 'Het is niet toegestaan om de App geheel of gedeeltelijk zonder schriftelijke toestemming van Bubblhub B.V. over te nemen, te vermenigvuldigen of op welke wijze dan ook te distribueren of openbaar te maken, met uitzondering van persoonlijk gebruik door de Gebruiker.',
                }
              },
              article_8: {
                title: 'Artikel 8 Aansprakelijkheid',
                content: {
                  item_1: 'De App is met de grootst mogelijke zorg samengesteld. Bubblhub B.V. kan echter niet garanderen dat de App altijd virusvrij zal zijn, zonder onderbreking, storing of fouten zal functioneren of beschikbaar zal zijn dan wel dat de informatie op de App altijd foutloos zal zijn.',
                  item_2: 'Bubblhub B.V. kan de App tijdelijk of permanent staken of buiten gebruik stellen, zonder dat de Gebruiker daar enige rechten aan kan ontlenen.',
                  item_3: 'Bubblhub B.V. is niet aansprakelijk voor enige schade (waaronder indirecte schade, gemiste besparingen, etc), verminking of verlies van data als gevolg van gebruik van de App.',
                  item_4: 'De Gebruiker is aansprakelijk voor alle handelingen die plaatsvinden via zijn/haar account. Bubblhub B.V. is niet aansprakelijke voor de uitingen en/of informatie die door Gebruikers worden verspreid via de App.',
                }
              },
              article_9: {
                title: 'Artikel 9 Wijziging gebruiksvoorwaarden',
                content: {
                  item_1: 'Bubblhub B.V. kan deze Gebruiksvoorwaarden aanpassen. Wijzigingen worden kenbaar gemaakt bij het gebruik van de App. Bij continuering van het gebruik van de App stemt de Gebruiker in met de gewijzigde Gebruiksvoorwaarden. Deze treden in werking vanaf het moment van bekendmaking of op een andere datum die wordt genoemd in de bekendmaking.',
                }
              },
              article_10: {
                title: 'Artikel 10 Overig',
                content: {
                  item_1: 'Op deze Gebruiksvoorwaarden is Nederlands recht van toepassing.',
                  item_2: 'Eventuele geschillen die voortvloeien uit het gebruik van de App of daarmee samenhangen worden voorgelegd aan de daartoe bevoegde rechter.',
                }
              },
            },
            privacy: {
              intro: 'BUBBLHUB B.V. is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring. Als een gebruiker inlogt gaat deze akkoord met de onderstaande voorwaarden.',
              email: {
                title: 'Email',
                data: 'info@bubblhub.com/sachaolieux@hotmail.com',
              },
              address: {
                title: 'Adres',
                data: 'Oostzijde 185',
              },
              phone: {
                title: 'Telefoonnummer',
                data: '+31642677837',
              },
              article_1: {
                title: 'Definities',
                content: {
                  item_1: 'Organisatie/klant: De organisatie/klant waarmee Bubblhub een dienstverband aangaat en die gebruikers verstrekt.',
                  item_2: 'Gebruikers: de personen die onder de organisatie gebruik maken van de App en gegevens verstrekken.',
                  item_3: 'Gebruiksperiode: de afgesproken tijd waar gebruikers gebruik kunnen maken van de App.',
                  item_4: 'Bubblhub: het bedrijf dat verschillende diensten aanbiedt waaronder een App.',
                  item_5: 'De App: de web-app die Bubblhub als dienst aanbiedt.',
                }
              },
              article_2: {
                title: 'Persoonsgegevens die wij verwerken',
                intro: 'BUBBLHUB verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:',
                content: {
                  item_1: 'Voor- en achternaam',
                  item_2: 'E-mailadres',
                  item_3: 'De actuele thema\'s die u invoert',
                  item_4: 'De BUBBLS waar u inzit of in heeft gezeten',
                  item_5: 'De UP\'s die u heeft geplaatst en heeft geliket',
                  item_6: 'De foto\'s die u plaatst',
                  item_7: 'Gegevens over uw activiteiten in onze App',
                },
                outro: 'Deze gegevens verwerken wij omdat dat nodig is voor een goede werking van de App (zie grondslag persoonsgegevens).'
              },
              article_3: {
                title: 'Bijzondere en/of gevoelige persoonsgegevens die wij verwerken',
                content: 'Onze App heeft niet de intentie gegevens te verzamelen over gebruikers die jonger zijn dan 18 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 18 is. Wij raden ouders dan ook aan betrokken te zijn bij de onlineactiviteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u ervan overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via info@bubblhub.com, dan verwijderen wij deze informatie.'
              },
              article_4: {
                title: 'Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken',
                intro: 'BUBBLHUB verwerkt uw persoonsgegevens voor de volgende doelen:',
                content: {
                  item_1: 'Om goederen en diensten bij u en uw organisatie af te leveren',
                  item_2: 'BUBBLHUB analyseert uw gedrag op de App om daarmee de App te verbeteren en het aanbod van producten en diensten af te stemmen op uw voorkeuren.',
                },
              },
              article_5: {
                title: 'Geautomatiseerde besluitvorming',
                content: 'BUBBLHUB neemt geen besluiten op basis van geautomatiseerde verwerkingen over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma\'s of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van BUBBLHUB) tussen zit.'
              },
              article_6: {
                title: 'Hoe lang we persoonsgegevens bewaren',
                content: 'BUBBLHUB bewaart uw persoonsgegevens niet langer dan de vooraf afgesproken gebruiksperiode. Na deze periode worden alle persoonsgegevens verwijderd binnen 1 week.'
              },
              article_7: {
                title: 'Delen van persoonsgegevens met derden',
                content: {
                  item_1: 'BUBBLHUB verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting.',
                  item_2: 'De persoonsgegevens die wij verzamelen worden gebruikt voor het opstellen van een data-analyse. Deze wordt met uw organisatie gedeeld. De gegevens in deze analyse zijn niet te herleiden naar u als gebruiker. De analyse bevat alleen generieke gegevens van alle gebruikers.',
                }
              },
              article_8: {
                title: 'Cookies, of vergelijkbare technieken, die wij gebruiken',
                content: 'BUBBLHUB gebruikt functionele cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze App wordt opgeslagen in de browser van uw computer, tablet of smartphone. BUBBLHUB gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de App naar behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de App goed te laten werken en deze te kunnen optimaliseren. Bij uw eerste bezoek aan onze App hebben wij u al geïnformeerd over deze cookies en toestemming gevraagd voor het plaatsen ervan. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen. Zie voor een toelichting:',
                link: 'https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/'
              },
              article_9: {
                title: 'Gegevens inzien, aanpassen of verwijderen',
                content: 'U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door BUBBLHUB en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar info@bubblhub.com. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek. BUBBLHUB wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link:',
                link: 'https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons'
              },
              article_10: {
                title: 'Hoe wij persoonsgegevens beveiligen',
                intro: 'BUBBLHUB neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op met onze klantenservice of via info@bubblhub.com. BUBBLHUB heeft de volgende maatregelen genomen om uw persoonsgegevens te beveiligen:',
                content: {
                  item_1: 'Beveiligingssoftware, zoals een virusscanner en firewall.',
                  item_2: 'TLS (voorheen SSL) Wij versturen uw gegevens via een beveiligde internetverbinding. Dit kunt u zien aan de adresbalk \'https\' en het hangslotje in de adresbalk.',
                  item_3: 'DKIM, SPF en DMARC zijn drie internetstandaarden die wij gebruiken om te voorkomen dat u uit onze naam e-mails ontvangt die virussen bevatten, spam zijn of bedoeld zijn om persoonlijke (inlog)gegevens te bemachtigen.',
                  item_4: 'DNSSEC is een extra beveiliging (aanvullend op DNS) voor het omzetten van een domeinnaam (#company_website) naar het hieraan gekoppelde IP-adres (servernaam); het wordt voorzien van een digitale handtekening. U kunt die handtekening automatisch laten controleren. Op die manier voorkomen wij dat u omgeleid wordt naar een vals IP-adres.',
                }
              },
            }
        },
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'nl',

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers/rootReducer';

// middlewares
import userMiddleware from '../middlewares/user';
import bubbleMiddleware from '../middlewares/bubble';
import notificationMiddleware from '../middlewares/notification';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['notification'],
    version: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line
const productionMiddlewares: any[] = [userMiddleware, bubbleMiddleware, notificationMiddleware];
// eslint-disable-next-line
const developmentMiddlewares: any[] = [];

const middlewares = [
    ...(process.env.NODE_ENV === 'production' ? [] : developmentMiddlewares),
    ...productionMiddlewares,
];
const middlewareEnhancer = applyMiddleware(...middlewares);

const composedEnhancer = composeWithDevTools(...[middlewareEnhancer]);
const store = createStore(persistedReducer, {}, composedEnhancer);

export const persistor = persistStore(store);

export default store;

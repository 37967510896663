export const SET_ACTIVE_CHAT = 'CHAT/SET_ACTIVE_CHAT';

export const SEND_MESSAGE = 'CHAT/SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'CHAT/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'CHAT/SEND_MESSAGE_FAILED';

export const ADD_MESSAGE_NOTIFICATION = 'CHAT/ADD_MESSAGE_NOTIFICATION';
export const REMOVE_MESSAGE_NOTIFICATION = 'CHAT/REMOVE_MESSAGE_NOTIFICATION';

export const ADD_RECENT_CONVO_NOTIFICATIONS = 'CHAT/ADD_RECENT_CONVO_NOTIFICATIONS';

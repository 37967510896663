import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import UserService from '../api/userService';

import { minimumCharactersPassword } from '../constants/global';

import { AppState } from '../redux/types/app';

import Header from '../components/Header';
import Loader from '../components/Loader';

import { setBackground, setShowNav } from '../redux/actions/app';

interface Props {
    setBackgroundFunc: (background: AppState['backgroundColor']) => void;
    backgroundColor: AppState['backgroundColor'];
    setShowNavFunc: (show: AppState['showNav']) => void;
    showNav: AppState['showNav'];
}

interface Input {
    'current-password': string;
    'new-password': string;
    'confirm-password': string;
}

const schema = yup.object().shape({
    'current-password': yup.string().required(i18n.t('form.password.required')).min(minimumCharactersPassword, i18n.t('form.password.minimumCharacters', { count: minimumCharactersPassword })),
    'new-password': yup.string().required(i18n.t('form.password.required')).min(minimumCharactersPassword, i18n.t('form.password.minimumCharacters', { count: minimumCharactersPassword })),
    'confirm-password': yup.string().required(i18n.t('form.password.required')).min(minimumCharactersPassword, i18n.t('form.password.minimumCharacters', { count: minimumCharactersPassword })),
});

const ResetPassword: React.FC<Props> = ({
    setBackgroundFunc,
    setShowNavFunc,
    showNav,
    backgroundColor,
}) => {
    const history = useHistory();
    const {
        register,
        handleSubmit,
        errors,
        setError,
    } = useForm<Input>({ resolver: yupResolver(schema) });
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');

    useEffect(() => {
        if (backgroundColor !== 'bg-p-1') setBackgroundFunc('bg-p-1');
        if (!showNav) setShowNavFunc(true);
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: Input) => {
        setLoading(true);
        if (data['new-password'] !== data['confirm-password']) {
            console.log('mismatch');
            setLoading(false);
            return setError('confirm-password', { type: 'validate', message: t('account.mismatch') });
        }
        if (data['new-password'] === data['current-password']) {
            setLoading(false);
            return setError('confirm-password', { type: 'validate', message: t('account.samePass') });
        }
        try {
            await UserService.changePassword(data['current-password'], data['new-password'], data['confirm-password']);
            setSuccessMessage(t('account.passChanged'));
            setLoading(false);
        } catch (err) {
            setApiError(err.message);
            setLoading(false);
        }
    };

    return (
        <>
            <Header
                Element={ (
                    <button
                        disabled={ loading }
                        onClick={ handleSubmit(onSubmit) }
                        className="absolute header-element right-0 pr-3 md:pr-5 hover:opacity-75"
                        type="submit"
                    >
                        { t('button.save') }
                    </button>
                ) }
                onClickOne={ () => history.goBack() }
                pageTitle={ t('account.reset') }
            />
            <form className="fade-in mt-10 mb-8 flex flex-col container relative" onSubmit={ handleSubmit(onSubmit) }>
                { loading && <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"><Loader className="z-10" /></div> }
                <div className="flex bg-s-7 p-5">
                    <label htmlFor="current-password" className="block w-40 text-gray-400">
                        { t('user.currentPassword') }
                    </label>
                    <input
                        id="current-password"
                        name="current-password"
                        type="password"
                        className="focus:outline-none bg-s-7 flex-grow ml-5"
                        ref={ register() }
                    />
                </div>
                {errors['current-password']?.message && <span className="p-1 mr-4 text-xs text-red-600 text-right">{ errors['current-password']?.message }</span> }
                <div className="flex bg-s-7 p-5">
                    <label htmlFor="new-password" className="block w-40 text-gray-400">
                        { t('user.newPassword') }
                    </label>
                    <input
                        id="new-password"
                        name="new-password"
                        type="password"
                        className="focus:outline-none bg-s-7 flex-grow ml-5"
                        ref={ register() }
                    />
                </div>
                {errors['new-password']?.message && <span className="p-1 mr-4 text-xs text-red-600 text-right">{ errors['new-password']?.message }</span> }
                <div className="flex bg-s-7 p-5">
                    <label htmlFor="confirm-password" className="block w-40 text-gray-400">
                        { t('user.confirm') }
                    </label>
                    <input
                        id="confirm-password"
                        name="confirm-password"
                        type="password"
                        className="focus:outline-none bg-s-7 flex-grow ml-5"
                        ref={ register() }
                    />
                </div>
                {errors['confirm-password']?.message && <span className="p-1 mr-4 text-red-600 text-xs text-right">{ errors['confirm-password']?.message }</span> }
                { apiError && (
                    <div className="text-red-500 py-10 px-8 text-center">
                        { apiError }
                    </div>
                ) }
                { successMessage && (
                    <div className="text-green-500 py-10 px-8 text-center">
                        { successMessage }
                    </div>
                ) }
            </form>
        </>
    );
};

interface State {
    app: AppState;
}

const mapStateToProps = ({ app }: State) => ({
    ...app,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

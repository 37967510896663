import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { State } from '../redux/reducers/rootReducer';
import { ReactComponent as AccountIcon } from '../svg/navbar-account.svg';
// import { ReactComponent as BubblsIcon } from '../svg/navbar-bubbls.svg';
import { ReactComponent as ChatsIcon } from '../svg/navbar-chats.svg';
// import { ReactComponent as HubIcon } from '../svg/navbar-hub.svg';
import { ReactComponent as NavbarCurve } from '../svg/navbar-curve.svg';
import Logo from '../svg/bubblhub-symbol.png';

const Navigation: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const notifications = useSelector((state: State) => state.chat.notifications);

    const renderNotification = () => {
        if (notifications && notifications.length) {
            return (
                <span className="absolute right-0 top-0 inline-flex rounded-full h-3 w-3 bg-teal-400">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-teal-400 opacity-75" />
                </span>
            );
        }
        return null;
    };

    return (
        <div className="bg-s-7 flex justify-around pb-4 pt-2 fixed bottom-0 left-0 right-0">
            <button
                onClick={ () => history.push('/account') }
                className="focus:outline-none text-center px-2 pb-2"
                type="button"
            >
                <AccountIcon className="w-8 mx-auto" />
                <span className="block text-xs font-bold">
                    { t('navigation.account') }
                </span>
            </button>

            <button
                onClick={ () => history.push('/mijn-bubbls') }
                className="focus:outline-none text-center px-2 pb-2 relative flex flex-col justify-end"
                type="button"
            >
                <NavbarCurve className="navbar-curve absolute transform z-0 left-0" />
                <div
                    className="nav-logo bg-g-1 rounded-full text-center absolute w-16 h-16 z-10"
                >
                    <img
                        className="w-6 absolute z-20"
                        alt="bubblehub logo"
                        src={ Logo }
                    />
                </div>
                <span className="block text-xs">
                    { t('navigation.myBubbls') }
                </span>
            </button>

            <button
                onClick={ () => history.push('/chats') }
                className="focus:outline-none text-center relative px-2 pb-2 z-10"
                type="button"
            >
                { renderNotification() }
                <ChatsIcon className="w-8 mx-auto" />
                <span className="block text-xs">
                    { t('navigation.chats') }
                </span>
            </button>

            {/* <button
                onClick={ () => history.push('/hub') }
                className="focus:outline-none text-center px-2 pb-2 z-10"
                type="button"
            >
                <HubIcon className="w-8 mx-auto" />
                <span className="block text-xs">
                    { t('navigation.hub') }
                </span>
            </button> */}

            {/* <button
                onClick={ () => history.push('/bubbls') }
                className="focus:outline-none text-center px-2 pb-2"
                type="button"
            >
                <BubblsIcon className="w-8 mx-auto" />
                <span className="block text-xs">
                    { t('navigation.bubbls') }
                </span>
            </button> */}

        </div>
    );
};

export default Navigation;

import React, { useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronRight } from '../svg/sendbutton.svg';

interface Props {
    onSend: (query: string) => void;
}

const ChatBox: React.FC<Props> = ({ onSend }) => {
    const [query, setQuery] = useState('');

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            onSend(query);
            setQuery('');
        }
    };

    const { t } = useTranslation();

    return (
        <div className="bg-p-1 flex fixed bottom-0 left-0 right-0 py-6 px-8 bg-s-7-b border-t-2 border-solid">
            <input
                className="bg-s-7 flex-grow p-3 pl-5 rounded-full min-w-0 focus:outline-none"
                name={ Math.random().toString(36).substring(7) }
                placeholder={ t('chat.type') }
                value={ query }
                onKeyDown={ handleKeyDown }
                onChange={ (e) => setQuery(e.target.value) }
            />
            <button
                className="bg-g-1 rounded-full ml-5 w-12 flex-shrink-0 focus:outline-none flex justify-center items-center"
                onClick={ () => {
                    onSend(query);
                    setQuery('');
                } }
                type="button"
            >
                <ChevronRight
                    className="w-8 max-w-full pl-1"
                />
            </button>
        </div>
    );
};

export default ChatBox;

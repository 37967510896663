import React, { useEffect, useState, useCallback } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserService from '../api/userService';

import Loader from '../components/Loader';

const Verify: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState<string>('');

    const verifyEmail = useCallback(async (user_pk: any, token: any) => {
        try {
            setLoading(true);
            await UserService.verify(user_pk, token);
            setLoading(false);
        } catch (err) {
            setApiError(err.message);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        let queryParamsString = window.location.search;

        if (queryParamsString.charAt(0) === '?') {
            queryParamsString = queryParamsString.substring(1);
        }
        const { user_pk, token } = qs.parse(queryParamsString);

        if (!user_pk || !token) throw new Error(t('error.contact'));

        verifyEmail(user_pk, token);
        // eslint-disable-next-line
    }, []);

    if (loading) return <div className="flex flex-grow items-center justify-center"><Loader /></div>;
    if (apiError) return <div className="flex flex-grow items-center justify-center"><span className="text-red-500">{ apiError }</span></div>;

    return (
        <div className="flex flex-grow items-center justify-center flex-col">
            <span className="text-gray-500 opacity-75 mb-10">{ t('user.verified') }</span>
            <button onClick={ () => history.push('/login') } className={ `btn bg-g-1 ${loading && 'opacity-25'}` } type="submit">{ t('button.login') }</button>

        </div>
    );
};

export default Verify;

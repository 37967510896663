import { Dispatch, MiddlewareAPI } from 'redux';
import {
    UserTypes,
    login as loginInterface,
    register as registerInterface,
    leaveBubble as leaveBubbleInterface,
    joinBubble as joinBubbleInterface,
    createBubble as createBubbleInterface,
    getBubbleMembers as getBubbleMembersInterface,
    changeName as changeNameInterface,
    changePhoto as changePhotoInterface,
} from '../types/user';
import {
    LOGIN,
    REGISTER,
    LEAVE_BUBBLE,
    GET_BUBBLES,
    JOIN_BUBBLE,
    CREATE_BUBBLE,
    GET_BUBBLE_MEMBERS,
    DELETE_ACCOUNT,
    CHANGE_NAME,
    SET_ACTIVE_BUBBLE,
    CHANGE_PHOTO,
} from '../constants/user';
import UserService from '../../api/userService';
import {
    loginFailed,
    loginSuccess,
    registerSuccess,
    registerFailed,
    joinBubbleFailed,
    joinBubbleSuccess,
    leaveBubbleFailed,
    leaveBubbleSuccess,
    getBubblesFailed,
    getBubblesSuccess,
    createBubbleSuccess,
    createBubbleFailed,
    getBubbleMembersFailed,
    getBubbleMembersSuccess,
    deleteAccountFailed,
    deleteAccountSuccess,
    changeNameFailed,
    changeNameSuccess,
    changePhotoFailed,
    changePhotoSuccess,
} from '../actions/user';

const register = async (store: MiddlewareAPI, action: registerInterface): Promise<void> => {
    const {
        email,
        password,
        onFinish,
        name,
    } = action;

    try {
        await UserService.register({ email, password, name });
        await store.dispatch(registerSuccess());
        onFinish();
    } catch (error) {
        await store.dispatch(registerFailed(error.message));
    }
};

const login = async (store: MiddlewareAPI, action: loginInterface): Promise<void> => {
    const { email, password, onFinish } = action;

    try {
        const {
            access, refresh, user, notification,
        } = await UserService.login({ email, password });
        await store.dispatch(loginSuccess(access, refresh, user, notification));
        onFinish();
    } catch (error) {
        await store.dispatch(loginFailed(error.message));
    }
};

const leaveBubble = async (store: MiddlewareAPI, action: leaveBubbleInterface): Promise<void> => {
    const { pk, id } = action;

    try {
        await UserService.leaveBubble(pk);
        await store.dispatch(leaveBubbleSuccess(id));
    } catch (error) {
        await store.dispatch(leaveBubbleFailed(error.message));
    }
};

const getBubbles = async (store: MiddlewareAPI): Promise<void> => {
    try {
        const bubbles = await UserService.getBubbles();
        if (bubbles.length) await store.dispatch(getBubblesSuccess(bubbles));
        if (!bubbles.length) await store.dispatch(getBubblesSuccess(null));
    } catch (error) {
        await store.dispatch(getBubblesFailed(error.message));
    }
};

const joinBubble = async (store: MiddlewareAPI, action: joinBubbleInterface): Promise<void> => {
    const { name } = action;

    try {
        const bubble = await UserService.createBubble(name);
        await store.dispatch(joinBubbleSuccess(bubble));
    } catch (error) {
        await store.dispatch(joinBubbleFailed(error.message));
    }
};

const createBubble = async (store: MiddlewareAPI, action: createBubbleInterface): Promise<void> => {
    const { name } = action;

    try {
        const bubble = await UserService.createBubble(name);
        await store.dispatch(createBubbleSuccess(bubble));
    } catch (error) {
        await store.dispatch(createBubbleFailed(error.message));
    }
};

const getBubbleMembers = async (store: MiddlewareAPI, action: getBubbleMembersInterface): Promise<void> => {
    const { id } = action;

    try {
        const bubble = await UserService.getBubbleMembers(id);
        await store.dispatch(getBubbleMembersSuccess(bubble.members));
    } catch (error) {
        await store.dispatch(getBubbleMembersFailed(error.message));
    }
};

const deleteAccount = async (store: MiddlewareAPI): Promise<void> => {
    try {
        await UserService.deleteAccount();
        await store.dispatch(deleteAccountSuccess());
    } catch (error) {
        await store.dispatch(deleteAccountFailed(error.message));
    }
};

const changeName = async (store: MiddlewareAPI, action: changeNameInterface): Promise<void> => {
    const { name } = action;
    try {
        const user = await UserService.changeName(name);
        await store.dispatch(changeNameSuccess(user));
    } catch (error) {
        await store.dispatch(changeNameFailed(error.message));
    }
};

const changePhoto = async (store: MiddlewareAPI, action: changePhotoInterface): Promise<void> => {
    const { formdata } = action;
    try {
        const { user } = await UserService.fileUpload(formdata);
        await store.dispatch(changePhotoSuccess(user));
    } catch (error) {
        await store.dispatch(changePhotoFailed(error.message));
    }
};

const userMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: UserTypes): Promise<void> | UserTypes | undefined => {
    switch (action.type) {
        case CHANGE_NAME:
            next(action);
            return changeName(store, action);
        case CHANGE_PHOTO:
            next(action);
            return changePhoto(store, action);
        case GET_BUBBLES:
            next(action);
            return getBubbles(store);
        case DELETE_ACCOUNT:
            next(action);
            return deleteAccount(store);
        case CREATE_BUBBLE:
            next(action);
            return createBubble(store, action);
        case GET_BUBBLE_MEMBERS:
            next(action);
            return getBubbleMembers(store, action);
        case JOIN_BUBBLE:
            next(action);
            return joinBubble(store, action);
        case LEAVE_BUBBLE:
            next(action);
            return leaveBubble(store, action);
        case LOGIN:
            next(action);
            return login(store, action);
        case SET_ACTIVE_BUBBLE:
            next(action);
            action.onFinish();
            break;
        case REGISTER:
            next(action);
            return register(store, action);
        default:
            return next(action);
    }
};

export default userMiddleware;

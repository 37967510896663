import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import { AppState } from '../redux/types/app';

import UserService from '../api/userService';

import Header from '../components/Header';
import TextLogo from '../components/TextLogo';
import Loader from '../components/Loader';

import { setBackground, setShowNav } from '../redux/actions/app';
import { enqueueSnackbar } from '../redux/actions/notification';

interface Props {
    setBackgroundFunc: (background: AppState['backgroundColor']) => void;
    backgroundColor: AppState['backgroundColor'];
    setShowNavFunc: (show: AppState['showNav']) => void;
    showNav: AppState['showNav'];
}

interface Input {
    email: string;
}

const schema = yup.object().shape({
    email: yup.string().email(i18n.t('form.email.valid')).required(i18n.t('form.email.required')),
});

const ForgotPassword: React.FC<Props> = ({
    setBackgroundFunc,
    setShowNavFunc,
    showNav,
    backgroundColor,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        errors,
    } = useForm<Input>({ resolver: yupResolver(schema) });
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (backgroundColor !== 'bg-p-1') setBackgroundFunc('bg-p-1');
        if (showNav) setShowNavFunc(false);
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: Input) => {
        setLoading(true);
        try {
            await UserService.forgotPassword(data.email);
            dispatch(enqueueSnackbar({
                message: t('general.success'),
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            }));
            history.push('registreren-success');
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    return (
        <>
            <Header
                onClickOne={ () => history.goBack() }
                pageTitle={ t('page.titles.reset') }
            />
            <TextLogo />
            <form className="fade-in px-6 mt-10 mb-8 flex flex-col container relative" onSubmit={ handleSubmit(onSubmit) }>
                { loading && <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"><Loader className="z-10" /></div> }
                <span className={ `input-wrapper gradient-border ${loading && 'opacity-25'}` }>
                    <input className="input bg-p-2 focus:outline-none" name="email" placeholder={ t('user.email') } ref={ register() } />
                </span>
                <span className="mb-2 pl-5">
                    { errors.email?.message }
                </span>
                <button className={ `btn bg-g-1 ${loading && 'opacity-25'}` } type="submit">{ t('button.send') }</button>
            </form>
            <div className="text-center text-grey px-8 mb-10">
                { t('account.forgot') }
            </div>
            { error && <span className="bg-red-500 w-full text-center">{ error }</span> }
        </>
    );
};

interface State {
    app: AppState;
}

const mapStateToProps = ({ app }: State) => ({
    ...app,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import {
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    LOG_OUT,
    DELETE_ACCOUNT,
    REGISTER,
    REGISTER_FAILED,
    REGISTER_SUCCESS,
    LEAVE_BUBBLE,
    LEAVE_BUBBLE_FAILED,
    LEAVE_BUBBLE_SUCCESS,
    GET_BUBBLES,
    GET_BUBBLES_FAILED,
    GET_BUBBLES_SUCCESS,
    JOIN_BUBBLE,
    JOIN_BUBBLE_FAILED,
    JOIN_BUBBLE_SUCCESS,
    CREATE_BUBBLE,
    CREATE_BUBBLE_FAILED,
    CREATE_BUBBLE_SUCCESS,
    SET_ACTIVE_BUBBLE,
    GET_BUBBLE_MEMBERS,
    GET_BUBBLE_MEMBERS_FAILED,
    GET_BUBBLE_MEMBERS_SUCCESS,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAILED,
    CHANGE_NAME,
    CHANGE_NAME_FAILED,
    CHANGE_NAME_SUCCESS,
    CHANGE_PHOTO,
    CHANGE_PHOTO_FAILED,
    CHANGE_PHOTO_SUCCESS,
} from '../constants/user';

import {
    UserTypes,
    loginInputs,
    registerInputs,
    bubble as BubbleType,
    member as MemberType,
    member,
} from '../types/user';
import { message as MessageType } from '../types/chat';

export const changePhoto = (formdata: FormData): UserTypes => ({
    type: CHANGE_PHOTO,
    formdata,
});

export const changePhotoSuccess = (user: member): UserTypes => ({
    type: CHANGE_PHOTO_SUCCESS,
    user,
});

export const changePhotoFailed = (error: string): UserTypes => ({
    type: CHANGE_PHOTO_FAILED,
    error,
});

export const changeName = (name: string): UserTypes => ({
    type: CHANGE_NAME,
    name,
});

export const changeNameSuccess = (user: MemberType): UserTypes => ({
    type: CHANGE_NAME_SUCCESS,
    user,
});

export const changeNameFailed = (error: string): UserTypes => ({
    type: CHANGE_NAME_FAILED,
    error,
});

export const setActiveBubble = (bubble: BubbleType, className: string, onFinish: () => void): UserTypes => ({
    type: SET_ACTIVE_BUBBLE,
    bubble,
    className,
    onFinish,
});

export const deleteAccount = (): UserTypes => ({
    type: DELETE_ACCOUNT,
});

export const deleteAccountSuccess = (): UserTypes => ({
    type: DELETE_ACCOUNT_SUCCESS,
});

export const deleteAccountFailed = (error: string): UserTypes => ({
    type: DELETE_ACCOUNT_FAILED,
    error,
});

export const logOut = (): UserTypes => ({
    type: LOG_OUT,
});

export const getBubbleMembers = (id: number): UserTypes => ({
    type: GET_BUBBLE_MEMBERS,
    id,
});

export const getBubbleMembersSuccess = (members: MemberType[]): UserTypes => ({
    type: GET_BUBBLE_MEMBERS_SUCCESS,
    members,
});

export const getBubbleMembersFailed = (error: string): UserTypes => ({
    type: GET_BUBBLE_MEMBERS_FAILED,
    error,
});

export const createBubble = (name: string): UserTypes => ({
    type: CREATE_BUBBLE,
    name,
});

export const createBubbleSuccess = (bubble: BubbleType): UserTypes => ({
    type: CREATE_BUBBLE_SUCCESS,
    bubble,
});

export const createBubbleFailed = (error: string): UserTypes => ({
    type: CREATE_BUBBLE_FAILED,
    error,
});

export const joinBubbleFailed = (error: string): UserTypes => ({
    type: JOIN_BUBBLE_FAILED,
    error,
});

export const joinBubbleSuccess = (bubble: BubbleType): UserTypes => ({
    type: JOIN_BUBBLE_SUCCESS,
    bubble,
});

export const joinBubble = (name: string): UserTypes => ({
    type: JOIN_BUBBLE,
    name,
});

export const getBubbles = (): UserTypes => ({
    type: GET_BUBBLES,
});

export const getBubblesSuccess = (bubbles: BubbleType[] | null): UserTypes => ({
    type: GET_BUBBLES_SUCCESS,
    bubbles,
});

export const getBubblesFailed = (error: string): UserTypes => ({
    type: GET_BUBBLES_FAILED,
    error,
});

export const login = ({ email, password }: loginInputs, onFinish: () => void): UserTypes => ({
    type: LOGIN,
    email,
    password,
    onFinish,
});

export const loginSuccess = (accessToken: string, refreshToken: string, user: MemberType, notifications: MessageType[]): UserTypes => ({
    type: LOGIN_SUCCESS,
    accessToken,
    refreshToken,
    user,
    notifications,
});

export const loginFailed = (error: string): UserTypes => ({
    type: LOGIN_FAILED,
    error,
});

export const register = ({ name, password, email }: registerInputs, onFinish: () => void): UserTypes => ({
    type: REGISTER,
    name,
    password,
    email,
    onFinish,
});

export const registerSuccess = (): UserTypes => ({
    type: REGISTER_SUCCESS,
});

export const registerFailed = (error: string): UserTypes => ({
    type: REGISTER_FAILED,
    error,
});

export const leaveBubble = (pk: string | undefined, id: number): UserTypes => ({
    type: LEAVE_BUBBLE,
    pk,
    id,
});

export const leaveBubbleSuccess = (id: number): UserTypes => ({
    type: LEAVE_BUBBLE_SUCCESS,
    id,
});

export const leaveBubbleFailed = (error: string): UserTypes => ({
    type: LEAVE_BUBBLE_FAILED,
    error,
});

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppState } from '../redux/types/app';
import { UserState, member as MemberType } from '../redux/types/user';
// import { ReactComponent as InzichtIcon } from '../svg/ups-symbol.svg';

import Loader from '../components/Loader';
import Member from '../components/Member';
import Header from '../components/Header';

import { setBackground, setShowNav } from '../redux/actions/app';
import { getBubbleMembers } from '../redux/actions/user';
import { setActiveChat } from '../redux/actions/chat';

interface Props {
    setBackgroundFunc: (background: AppState['backgroundColor']) => void;
    backgroundColor: AppState['backgroundColor'];
    active: UserState['active'];
    getBubbleMembersFunc: (id: number) => void;
    setShowNavFunc: (show: AppState['showNav']) => void;
    setActiveChatFunc: (member: MemberType) => void;
    loading: UserState['loading'];
    showNav: AppState['showNav'];
    user: UserState['user'];
}

const Climate: React.FC<Props> = ({
    setBackgroundFunc,
    getBubbleMembersFunc,
    setActiveChatFunc,
    setShowNavFunc,
    showNav,
    backgroundColor,
    active,
    loading,
    user,
}) => {
    const history = useHistory();

    const { t } = useTranslation();
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        if (!active.id) {
            history.push('/mijn-bubbls');
        } else if (!loading && (!active.members || !active.members.length || active.members.length !== 1)) {
            getBubbleMembersFunc(active.id);
        }
        if (!showNav) setShowNavFunc(true);
        if (active.className && backgroundColor !== active.className) setBackgroundFunc(active.className);
        // eslint-disable-next-line
    }, []);

    const handleMemberClick = (member: MemberType) => {
        setActiveChatFunc(member);
        history.push('/chat');
    };

    const renderMembers = () => {
        if (loading) return <div className="flex flex-grow items-center justify-center"><Loader /></div>;

        if (active.members) {
            if (active.members.length === 1) {
                return <div className="flex flex-grow items-center justify-center">{ t('bubbles.noMembers') }</div>;
            }
            return (
                <div className="fade-in">
                    <div className="pl-6 py-4 text-gray-400">
                        <span className="uppercase">{ t('bubbles.mensen') }</span>
                        <span className="pl-20 md:pl-40">
                            { (active.members.length - 1) }
                            {' '}
                            { t('bubbles.members') }
                        </span>
                    </div>
                    <div className="divide-y divide-gray-400 divide-opacity-25">
                        { active.members.map((member, index) => {
                            if (member.pk !== user.pk && member.name.toLowerCase().includes(query.toLocaleLowerCase())) {
                                return <Member onClick={ handleMemberClick } member={ member } key={ index } />;
                            }
                            return null;
                        }) }
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <Header
                onChange={ setQuery }
                value={ query }
                LeftElement={ (
                    <button
                        onClick={ () => history.push(`/ups/${active.id}`) }
                        className="absolute header-element focus:outline-none left-0 pl-2 md:pl-5 hover:opacity-75"
                        type="submit"
                    >
                        {/* <InzichtIcon className="w-8" /> @TODO @TEMPORARY LET OP: dit is het up-icoon */}
                    </button>
                ) }
                upperCase={ false }
                onClickOne={ () => history.goBack() }
                pageTitle={ active.name }
            />
            { renderMembers() }
        </>
    );
};

interface State {
    app: AppState;
    user: UserState;
}

const mapStateToProps = ({ app, user }: State) => ({
    ...app,
    ...user,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    getBubbleMembersFunc: (id: number) => dispatch(getBubbleMembers(id)),
    setActiveChatFunc: (member: MemberType) => dispatch(setActiveChat(member)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Climate);

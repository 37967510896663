import { AppState, AppTypes } from '../types/app';
import { SET_BACKGROUND, SET_SHOWNAV, SET_SHOWMODAL } from '../constants/app';
import { LOG_OUT } from '../constants/user';

const initialState: AppState = {
    backgroundColor: 'bg-p-1',
    showNav: false,
    modal: {
        show: false,
        option: 'insights',
    },
};

const app = (state = initialState, action: AppTypes): AppState => {
    switch (action.type) {
        case SET_BACKGROUND:
            return { ...state, backgroundColor: action.background };
        case SET_SHOWNAV:
            return { ...state, showNav: action.show };
        case SET_SHOWMODAL:
            return { ...state, modal: { ...state.modal, show: action.show, option: action.option } };
        case LOG_OUT:
            return { ...state, modal: { ...state.modal, show: false, option: 'insights' } };
        default:
            return state;
    }
};

export default app;

import React from 'react';
import Placeholder from '../svg/user-placeholder.jpg';
import { imagePrefix } from '../constants/global';

import { member as MemberType } from '../redux/types/user';

interface Props {
    member: MemberType;
    onClick: (member: MemberType) => void;
    notification?: boolean;
}

const Member: React.FC<Props> = ({ member, onClick, notification = false }) => {
    const { name } = member;
    const { url } = member.picture;
    return (
        <button
            onClick={ () => onClick(member) }
            type="button"
            className="focus:outline-none flex w-full items-center p-2"
        >
            <div className="ml-6 mr-6 w-10 h-10 rounded-full bg-cover bg-center bg-no-repeat relative" style={ { backgroundImage: url ? `url(${imagePrefix}${url})` : `url(${Placeholder})` } }>
                {
                    notification && (
                        <span className="absolute member-notification top-0 inline-flex rounded-full h-3 w-3 bg-teal-400">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-teal-400 opacity-75" />
                        </span>
                    )
                }
                {/* <img
                    alt="BubbleHub profiel foto"
                    className="rounded-full w-10 h-10 object-cover"
                    src={ member.picture.url ? `${imagePrefix}${member.picture.url}` : Placeholder }
                /> */}
            </div>
            <div>
                { name }
            </div>
        </button>
    );
};

export default Member;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import { AppState } from '../redux/types/app';

import Header from '../components/Header';

import { setBackground } from '../redux/actions/app';

// import InfoPNG from '../png/info.png';
// import { ReactComponent as ArrowRight } from '../svg/arrow-right.svg';

import ReactPlayer from 'react-player'

interface Props {
    setBackgroundFunc: (background: AppState['backgroundColor']) => void
    backgroundColor: AppState['backgroundColor']
}

// const slides = [
//     {
//         title: 'Welkom!',
//         content: `Welkom bij BUBBLHUB. De app die het mogelijk maakt om met iedereen in jouw organisatie
//         gesprekken te voeren over actuele thema’s. We hopen dat je het naar je zin hebt in deze app
//         en dat jij en jouw organisatie veel van elkaar kunnen leren. In de rest van de infopages kan je
//         zien hoe de verschillende onderdelen van BUBBLHUB werken. Veel BUBBL plezier!`,
//         url: InfoPNG,
//     },
//     {
//         title: 'Hoe werkt BUBBLHUB?',
//         content: `BUBBLHUB werkt doordat iedere gebruiker zoveel mogelijk actuele thema’s toevoegt. Al
//         deze thema’s worden samengebracht in BUBBLS. Deze BUBBLS deel je met de mensen die
//         hetzelfde thema hebben gekozen. In sommige BUBBLS zitten veel mensen en in andere
//         minder. Hoe meer mensen er in een BUBBL zitten hoe meer gesprekken je kan voeren. Via
//         deze gesprekken kunnen UP’s geplaatst worden. UP’s zijn inzichten die je naar boven wil
//         halen in jouw organisatie.`,
//         url: InfoPNG,
//     },
//     {
//         title: 'Actuele thema’s',
//         content: `Actuele thema’s zijn de thema’s waar jij je mee bezig houdt in jouw organisatie. Het zijn de
//         thema’s die jou aan het hart gaan, de thema’s waar je je voor wil inzetten, de thema’s die je
//         interesseren en de thema’s die je wil veranderen. Deze thema’s kunnen ook erg persoonlijk
//         zijn. Het kan te maken hebben met een idee waar je al een tijdje aan werkt of een gevoel
//         waar je al even mee zit. Mogelijk zelfs een persoonlijke missie waar je je voor inzet binnen in
//         jouw organisatie. Kortom actuele thema’s zijn erg breed, hoe meer thema’s hoe beter.
//         TIP: als je een breed thema invoert zullen de gesprekken en UP’s ook erg uiteenlopen en
//         breed zijn. Het thema “HRM” is zo een voorbeeld waardoor er veel verschillende soorten
//         gesprekken zullen zijn en niet iedereen vindt wat hij/zij zoekt. Als je in de BUBBL “HRM” zit
//         om gesprekken te voeren over een “nieuw onboarding programma” adviseren wij om het
//         “onboarding programma” als nieuw thema toe te voegen. Op deze manier krijg je de
//         gesprekken en de UP’s die jij en andere zoeken.`,
//         url: InfoPNG,
//     },
//     {
//         title: 'BUBBL',
//         content: `Een thema wordt een BUBBL als er twee of meer mensen in zitten. In sommige BUBBLS
//         zitten meer mensen dan in andere BUBBLS. Als je vooral veel gesprekken wil voeren is het
//         raadzaam BUBBLS te zoeken die populair zijn en veel mensen bevatten om mee in gesprek te
//         komen.`,
//         url: InfoPNG,
//     },
//     {
//         title: 'Chat',
//         content: `In de chats kun je met elkaar praten over de BUBBL waar je samen inzit. Je kan elkaar vragen
//         waarom die persoon in de BUBBL zit, waar die aan werkt of waar die het over wil hebben. Zo
//         breng je samen het gesprek op gang en nodig je elkaar uit om meer te delen. Gebruikers
//         kunnen de chat ook gebruiken of afspraken te maken om elkaar op kantoor te zien of via
//         videobellen een gesprek te beginnen. Dit moedigen wij aan. De chat is een tool om het
//         eerste contact te leggen met elkaar. Geen vereiste voor al het verdere contact. De chat is
//         wel de enige plaats waar je UP’s kan plaatsen.`,
//         url: InfoPNG,
//     },
//     {
//         title: 'UP',
//         content: `Een UP is een inzicht, idee, advies of mening die je wil delen met de hele BUBBL. Iedereen
//         kan in één BUBBL maximaal 3 UP’s plaatsen en maximaal 3 Like’s geven voor een UP. De
//         meest gelikete UP staat bovenaan in de lijst van UP’s.`,
//         url: InfoPNG,
//     },
//     {
//         title: 'HUB',
//         content: `De HUB is de plaats waar de 7 grootste BUBBLS van de organisatie samen komen. Via de
//         HUB kan je geen gesprekken starten maar wel de UP’s bekijken. De HUB is continue in
//         verandering omdat alle BUBBLS in de organisatie dat ook zijn. Het kan zo maar voorkomen
//         dat er iedere week nieuwe BUBBLS in de HUB staan.`,
//         url: InfoPNG,
//     },
// ];

const Info: React.FC<Props> = ({ setBackgroundFunc, backgroundColor }) => {
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     adaptiveHeight: true,
    // };

    const history = useHistory();

    const { t } = useTranslation();

    useEffect(() => {
        if (backgroundColor !== 'bg-g-1') setBackgroundFunc('bg-g-1');
    });

    return (
        <>
            <Header onClickOne={ () => history.goBack() } pageTitle={ t('page.titles.info') } />
            {/* <div className="mt-16 text-center fade-in">
                <Slider { ...settings }>
                    {
                        slides && slides.map(({ title, content, url }, index) => (
                            <div className="px-16 outline-none" key={ `${index}+${title}` }>
                                <img className="mx-auto" alt="BubblHub Information" src={ url } />
                                <h3 className="mt-10">{ title }</h3>
                                <h5 className="mt-10 mb-5">{ content }</h5>
                            </div>
                        ))
                    }
                </Slider>
            </div> */}
            <div className="mt-5" style={{display: 'flex', justifyContent: 'center'}}>
              <ReactPlayer controls
                url="https://www.youtube.com/watch?v=yfz0vyHBPTU"
              />
            </div>
            {/* <button
                onClick={ () => history.push('/login') }
                className="my-10 px-12 block mx-auto focus:outline-none rounded-full bg-s-3 p-3"
                type="button"
            >
                <ArrowRight className="w-6" />
            </button> */}
        </>
    );
};

interface State {
    app: AppState
}

const mapStateToProps = ({ app }: State) => ({
    ...app,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Info);

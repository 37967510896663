import React from 'react';

import { ReactComponent as Logo } from '../svg/text-logo.svg';

const TextLogo: React.FC = () => (
    <div className="flex items-center justify-center py-12">
        <Logo className="info-logo max-w-xs lg:max-w-lg" title="BubbleHub Text Logo" />
    </div>
);

export default TextLogo;

export const LOGIN = 'USER/LOGIN';
export const LOGIN_SUCCESS = 'USER/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'USER/LOGIN_FAILED';

export const LOG_OUT = 'USER/LOG_OUT';

export const DELETE_ACCOUNT = 'USER/DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'USER/DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILED = 'USER/DELETE_ACCOUNT_FAILED';

export const REGISTER = 'USER/REGISTER';
export const REGISTER_SUCCESS = 'USER/REGISTER_SUCCESS';
export const REGISTER_FAILED = 'USER/REGISTER_FAILED';

export const JOIN_BUBBLE = 'BUBBLE/JOIN_BUBBLE';
export const JOIN_BUBBLE_SUCCESS = 'BUBBLE/JOIN_BUBBLE_SUCCESS';
export const JOIN_BUBBLE_FAILED = 'BUBBLE/JOIN_BUBBLE_FAILED';

export const LEAVE_BUBBLE = 'USER/LEAVE_BUBBLE';
export const LEAVE_BUBBLE_SUCCESS = 'USER/LEAVE_BUBBLE_SUCCESS';
export const LEAVE_BUBBLE_FAILED = 'USER/LEAVE_BUBBLE_FAILED';

export const GET_BUBBLES = 'USER/GET_BUBBLES';
export const GET_BUBBLES_SUCCESS = 'USER/GET_BUBBLES_SUCCESS';
export const GET_BUBBLES_FAILED = 'USER/GET_BUBBLES_FAILED';

export const CREATE_BUBBLE = 'USER/CREATE_BUBBLE';
export const CREATE_BUBBLE_SUCCESS = 'USER/CREATE_BUBBLE_SUCCESS';
export const CREATE_BUBBLE_FAILED = 'USER/CREATE_BUBBLE_FAILED';

export const SET_ACTIVE_BUBBLE = 'USER/SET_ACTIVE_BUBBLE';

export const GET_BUBBLE_MEMBERS = 'USER/GET_BUBBLE_MEMBERS';
export const GET_BUBBLE_MEMBERS_SUCCESS = 'USER/GET_BUBBLE_MEMBERS_SUCCESS';
export const GET_BUBBLE_MEMBERS_FAILED = 'USER/GET_BUBBLE_MEMBERS_FAILED';

export const CHANGE_NAME = 'USER/CHANGE_NAME';
export const CHANGE_NAME_SUCCESS = 'USER/CHANGE_NAME_SUCCESS';
export const CHANGE_NAME_FAILED = 'USER/CHANGE_NAME_FAILED';

export const CHANGE_PHOTO = 'USER/CHANGE_PHOTO';
export const CHANGE_PHOTO_SUCCESS = 'USER/CHANGE_PHOTO_SUCCESS';
export const CHANGE_PHOTO_FAILED = 'USER/CHANGE_PHOTO_FAILED';

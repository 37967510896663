export const minimumCharactersPassword = 8;

export const imagePrefix = '';

export const editBubbleColorOrder: string[] = [
    'bubble-bg-5',
    'bubble-bg-1',
    'bubble-bg-7',
    'bubble-bg-3',
    'bubble-bg-4',
    'bubble-bg-6',
    'bubble-bg-2',
];

export const myBubbleColorOrder: string[] = [
    'bubble-bg-4',
    'bubble-bg-1',
    'bubble-bg-7',
    'bubble-bg-5',
    'bubble-bg-3',
    'bubble-bg-2',
    'bubble-bg-6',
];

export const myBubbleLocations = [
    { top: 80, left: -40 },
    { top: 338, right: -27 },
    { top: -10, left: 125 },
    { top: 65, right: -10 },
    { top: 197, right: 54 },
    { top: 283, left: 73 },
    { top: 419, left: 30 },
];

export const myBubbleSizes: number[] = [
    1,
    1,
    0.1,
    0.1,
    0.1,
    0.1,
    0.1,
];

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Pusher from 'pusher-js';

import { AppState } from '../redux/types/app';
import { UserState, bubble as BubbleType } from '../redux/types/user';

import Header from '../components/Header';
import Bubble from '../components/Bubble';

import { ReactComponent as BubblsIcon } from '../svg/navbar-bubbls.svg';

import { myBubbleLocations, myBubbleColorOrder, myBubbleSizes } from '../constants/global';

import { setBackground, setShowNav } from '../redux/actions/app';
import { getBubbles, setActiveBubble } from '../redux/actions/user';

interface Props {
    setBackgroundFunc: (background: AppState['backgroundColor']) => void;
    backgroundColor: AppState['backgroundColor'];
    setShowNavFunc: (show: AppState['showNav']) => void;
    showNav: AppState['showNav'];
    bubbles: UserState['bubbles'];
    getBubblesFunc: () => void;
    setActiveBubbleFunc: (bubble: BubbleType, className: string, onFinish: () => void) => void;
}

const MyBubbles: React.FC<Props> = ({
    setBackgroundFunc,
    setShowNavFunc,
    getBubblesFunc,
    setActiveBubbleFunc,
    backgroundColor,
    showNav,
    bubbles,
}) => {
    const history = useHistory();

    const { t } = useTranslation();

    useEffect(() => {
        if (backgroundColor !== 'bg-p-2') setBackgroundFunc('bg-p-2');
        if (!showNav) setShowNavFunc(true);
        if (!bubbles || !bubbles.length) getBubblesFunc();

        // eslint-disable-next-line
    }, []);

    const renderBubbles = () => {
        if (bubbles && bubbles.length) {
            return bubbles.map((bub, index) => (
                <Bubble
                    key={ `${bub.name}-${index}` }
                    text={ bub.name }
                    className={ myBubbleColorOrder[index] }
                    size={ myBubbleSizes[index] }
                    placement={ myBubbleLocations[index] }
                    onClick={ () => {
                        setBackgroundFunc(myBubbleColorOrder[index]);
                        setActiveBubbleFunc(bub, myBubbleColorOrder[index], () => history.push('/klimaat'));
                    } }
                />
            ));
        }
        return null;
    };

    return (
        <>
          <Header pageTitle={ t('page.titles.my') }
              Element={ (
                <button
                  onClick={ () => history.push('/bubbls') }
                  className="absolute header-element focus:outline-none right-0 pr-3 md:pr-5 hover:opacity-75"
                  type="button"
              >
                  <BubblsIcon className="w-9 mx-auto" />
                  {/* <span className="block text-xs">
                      { t('navigation.bubbls') }
                  </span> */}
              </button>
              ) }
          />
          <div className="my-bubbles-container fade-in flex flex-grow flex-col md:flex-grow-0 container relative">
              { renderBubbles() }
          </div>
        </>
    );
};

interface State {
    app: AppState;
    user: UserState;
}

const mapStateToProps = ({ app, user }: State) => ({
    ...app,
    ...user,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
    getBubblesFunc: () => dispatch(getBubbles()),
    setActiveBubbleFunc: (bubble: BubbleType, className: string, onFinish: () => void) => dispatch(setActiveBubble(bubble, className, onFinish)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyBubbles);

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppState } from '../redux/types/app';

import Header from '../components/Header';
import TextLogo from '../components/TextLogo';

import { setBackground, setShowNav } from '../redux/actions/app';

interface Props {
    setBackgroundFunc: (background: string) => void;
    backgroundColor: AppState['backgroundColor'];
    showNav: AppState['showNav'];
}

const PrivacyPolicy: React.FC<Props> = ({ setBackgroundFunc, backgroundColor, showNav }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (backgroundColor !== 'bg-p-1') setBackgroundFunc('bg-p-1');
        if (showNav) dispatch(setShowNav(false));
    });

    return (
        <>
            <Header
                onClickOne={ () => history.goBack() }
                pageTitle={ t('account.privacy') } />
            <TextLogo />
            <div className="flex-grow justify-center mx-3 mb-8 p-5 pr-8 overflow-scroll h-1" style={{color: 'black', background: 'white',borderRadius: '20px'}}>
              <b style={{fontSize: '20px'}}>{ t('account.privacy') }</b>
              <br/>
              { t('privacy.intro') }
              <br/>
              <br/>
              <b>{ t('privacy.email.title') }</b>
              <br/>
              { t('privacy.email.data') }
              <br/>
              <b>{ t('privacy.address.title') }</b>
              <br/>
              { t('privacy.address.data') }
              <br/>
              <b>{ t('privacy.phone.title') }</b>
              <br/>
              { t('privacy.phone.data') }
              <br/>
              <br/>
              <b>{ t('privacy.article_1.title') }</b>
              <ul className="list-disc pl-4">
                <li>{ t('privacy.article_1.content.item_1') }</li>
                <li>{ t('privacy.article_1.content.item_2') }</li>
                <li>{ t('privacy.article_1.content.item_3') }</li>
                <li>{ t('privacy.article_1.content.item_4') }</li>
                <li>{ t('privacy.article_1.content.item_5') }</li>
              </ul>
              <br/>
              <b>{ t('privacy.article_2.title') }</b>
              <br/>
              { t('privacy.article_2.intro') }
              <br/>
              <ul className="list-disc pl-4">
                <li>{ t('privacy.article_2.content.item_1') }</li>
                <li>{ t('privacy.article_2.content.item_2') }</li>
                <li>{ t('privacy.article_2.content.item_3') }</li>
                <li>{ t('privacy.article_2.content.item_4') }</li>
                <li>{ t('privacy.article_2.content.item_5') }</li>
                <li>{ t('privacy.article_2.content.item_6') }</li>
                <li>{ t('privacy.article_2.content.item_7') }</li>
              </ul>
              <br/>
              { t('privacy.article_2.outro') }
              <br/>
              <br/>
              <b>{ t('privacy.article_3.title') }</b>
              <br/>
              { t('privacy.article_3.content') }
              <br/>
              <br/>
              <b>{ t('privacy.article_4.title') }</b>
              <br/>
              { t('privacy.article_4.intro') }
              <br/>
              <ul className="list-disc pl-4">
                <li>{ t('privacy.article_4.content.item_1') }</li>
                <li>{ t('privacy.article_4.content.item_2') }</li>
              </ul>
              <br/>
              <b>{ t('privacy.article_5.title') }</b>
              <br/>
              { t('privacy.article_5.content') }
              <br/>
              <br/>
              <b>{ t('privacy.article_6.title') }</b>
              <br/>
              { t('privacy.article_6.content') }
              <br/>
              <br/>
              <b>{ t('privacy.article_7.title') }</b>
              <br/>
              { t('privacy.article_7.content.item_1') }
              <br/>
              <br/>
              { t('privacy.article_7.content.item_2') }
              <br/>
              <br/>
              <b>{ t('privacy.article_8.title') }</b>
              <br/>
              { t('privacy.article_8.content') }
              <br/>
              <a className="underline" target="_blank" rel="noopener noreferrer" href={ t('privacy.article_8.link') }>Wat zijn cookies?</a>
              <br/>
              <br/>
              <b>{ t('privacy.article_9.title') }</b>
              <br/>
              { t('privacy.article_9.content') }
              <br/>
              <a className="underline" target="_blank" rel="noopener noreferrer" href={ t('privacy.article_9.link') }>Klacht melden</a>
              <br/>
              <br/>
              <b>{ t('privacy.article_10.title') }</b>
              <br/>
              { t('privacy.article_10.intro') }
              <br/>
              <ul className="list-disc pl-4">
                <li>{ t('privacy.article_10.content.item_1') }</li>
                <li>{ t('privacy.article_10.content.item_2') }</li>
                <li>{ t('privacy.article_10.content.item_3') }</li>
                <li>{ t('privacy.article_10.content.item_4') }</li>
              </ul>
            </div>
        </>
    );
};

interface State {
    app: AppState
}

const mapStateToProps = ({ app }: State) => ({
    ...app,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);

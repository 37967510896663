import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { AppState } from '../redux/types/app';
import { UserState } from '../redux/types/user';
import i18n from '../i18n';

import { ReactComponent as DeleteIcon } from '../svg/delete-account.svg';
import { ReactComponent as LogOutIcon } from '../svg/log-out.svg';
import { ReactComponent as ArrowRight } from '../svg/arrow-right.svg';

import { setBackground, setShowNav } from '../redux/actions/app';
import { enqueueSnackbar } from '../redux/actions/notification';
import {
    logOut, deleteAccount, changeName, changePhoto,
} from '../redux/actions/user';

import Header from '../components/Header';
import Loader from '../components/Loader';
import ImageUpload from '../components/ImageUpload';

interface Props {
    setBackgroundFunc: (backgroundColor: AppState['backgroundColor']) => void;
    backgroundColor: AppState['backgroundColor'];
    setShowNavFunc: (show: AppState['showNav']) => void;
    showNav: AppState['showNav'];
    logOutFunc: () => void;
    deleteAccountFunc: () => void;
    changeNameFunc: (name: string) => void;
    user: UserState['user'];
    loading: UserState['loading'];
}

interface Input {
    name: string;
}

const schema = yup.object().shape({
    name: yup.string().required(i18n.t('form.name.required')).min(2, i18n.t('form.name.minimumCharacters', { count: 2 })),
});

const Account: React.FC<Props> = ({
    setShowNavFunc,
    setBackgroundFunc,
    deleteAccountFunc,
    logOutFunc,
    changeNameFunc,
    showNav,
    backgroundColor,
    user,
    loading,
}) => {
    const dispatch = useDispatch();
    const changeProfilePhoto = (formdata: FormData) => dispatch(changePhoto(formdata));
    const history = useHistory();
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setValue,
        errors,
    } = useForm<Input>({ resolver: yupResolver(schema) });

    useEffect(() => {
        if (!user.pk) history.push('/login');
        if (backgroundColor !== 'bg-p-2') setBackgroundFunc('bg-p-2');
        if (!showNav) setShowNavFunc(true);

        setValue('name', user.name);
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: Input) => {
        changeNameFunc(data.name);
        dispatch(enqueueSnackbar({
            message: t('general.success'),
            options: {
                variant: 'success',
                key: new Date().getTime() + Math.random(),
            },
        }));
    };

    const handleOnchange = (files: FileList | null) => {
        if (files && files.length) {
            const formData = new FormData();
            console.log(files);
            formData.append('file', files[0]);
            changeProfilePhoto(formData);
        }
    };

    return (
        <>
            <Header
                Element={ (
                    <button
                        onClick={ handleSubmit(onSubmit) }
                        className="absolute header-element right-0 pr-3 md:pr-5 hover:opacity-75"
                        type="submit"
                    >
                        { t('button.save') }
                    </button>
                ) }
                onClickOne={ () => history.goBack() }
                pageTitle={ t('page.titles.account') }
            />
            <div className="fade-in">
                <ImageUpload
                    imageSrc={ user.picture.url }
                    onChange={ handleOnchange }
                />
            </div>
            <span className="uppercase text-xs text-gray-400 ml-4 pb-1">
                { t('account.data') }
            </span>
            <form>
                { loading && <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"><Loader className="z-10" /></div> }
                <div className="flex bg-s-7 p-5">
                    <label htmlFor="email" className="block text-gray-400 flex-shrink-0">
                        { t('user.email') }
                    </label>
                    <input
                        value={ user.email }
                        disabled
                        id="email"
                        name="email"
                        className="focus:outline-none bg-s-7 flex-grow ml-10"
                    />
                </div>
                <div className="flex bg-s-7 p-5">
                    <label htmlFor="name" className="block text-gray-400">
                        { t('user.name') }
                    </label>
                    <input
                        id="name"
                        name="name"
                        ref={ register() }
                        className="focus:outline-none bg-s-7 flex-grow ml-10"
                    />
                </div>
                {errors.name?.message && <div className="p-1 mr-4 text-xs text-red-600 text-right">{ errors.name?.message }</div> }
            </form>
            <button onClick={ () => history.push('/wachtwoord-resetten') } type="button" className="focus:outline-none flex bg-s-7 p-5">
                <span className="flex-grow text-left">
                    { t('account.reset') }
                </span>
                <ArrowRight className="w-6" />
            </button>
            <span className="uppercase text-xs text-gray-400 ml-4 pb-1 mt-8">
                { t('account.overige') }
            </span>
            <button onClick={ () => history.push('/algemene-voorwaarden') } type="button" className="focus:outline-none flex bg-s-7 p-5">
                <span className="flex-grow text-left">
                    { t('account.terms') }
                </span>
                <ArrowRight className="w-6" />
            </button>
            <button onClick={ () => history.push('/privacy-beleid') } type="button" className="focus:outline-none flex bg-s-7 p-5">
                <span className="flex-grow text-left">
                    { t('account.privacy') }
                </span>
                <ArrowRight className="w-6" />
            </button>
            <button onClick={ () => logOutFunc() } type="button" className="focus:outline-none flex bg-s-7 p-5">
                <LogOutIcon className="w-6 mr-6" />
                <span className="flex-grow text-left text-red-600">
                    { t('account.logout') }
                </span>
            </button>
            <button onClick={ () => deleteAccountFunc() } type="button" className="focus:outline-none flex bg-s-7 p-5">
                <DeleteIcon className="w-6 mr-6" />
                <span className="flex-grow text-left text-red-600">
                    { t('account.delete') }
                </span>
            </button>
        </>
    );
};

interface State {
    app: AppState;
    user: UserState;
}

const mapStateToProps = ({ app, user }: State) => ({
    ...app,
    ...user,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
    logOutFunc: () => dispatch(logOut()),
    deleteAccountFunc: () => dispatch(deleteAccount()),
    changeNameFunc: (name: string) => dispatch(changeName(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);

import React, { lazy, Suspense } from 'react';
import { ReactComponent as CrossIcon } from '../svg/exit-icon.svg';
import { option as OptionType } from '../redux/types/app';
import Loader from './Loader';

const InsightsModal = lazy(() => import('../modals/InsightsModal'));

interface Props {
    onClick: (show: boolean) => void;
    option: OptionType;
    modalProps: any;
}

const Modal: React.FC<Props> = ({ onClick, option, modalProps }) => {
    const getContent = () => {
        let content = null;
        switch (option) {
            case 'insights':
                content = <InsightsModal { ...modalProps } />;
                break;
            case 'forgot-password':
                content = <span>Forgot Password</span>;
                break;
            default:
                content = null;
        }
        return <Suspense fallback={ <div className="flex justify-center"><Loader /></div> }>{ content }</Suspense>;
    };
    return (
        <>
            <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center">
                <div className="mx-3 p-2 pt-2 relative w-full md:mx-auto rounded-lg md:w-6/12 lg:w-5/12 xl:w-4/12 bg-s-7 z-40">
                    <button onClick={ () => onClick(false) } type="button">
                        <CrossIcon className="w-10 absolute right-0 top-0 m-2" />
                    </button>

                    { getContent() }
                </div>
            </div>
            {/* eslint-disable-next-line */ }
            <div role="button" onClick={ () => onClick(false) } className="modal-overlay absolute top-0 right-0 left-0 bottom-0 z-30" />
        </>
    );
};

export default Modal;

import { BubbleState, BubbleTypes } from '../types/bubble';
import {
    SEARCH_BUBBLES,
    SEARCH_BUBBLES_SUCCESS,
    SEARCH_BUBBLES_FAILED,
    GET_COMPANY_BUBBLES,
    GET_COMPANY_BUBBLES_FAILED,
    GET_COMPANY_BUBBLES_SUCCESS,
} from '../constants/bubble';

const initialState: BubbleState = {
    companyBubbles: [],
    loading: false,
    error: '',
    searchResults: [],
};

const bubble = (state = initialState, action: BubbleTypes): BubbleState => {
    switch (action.type) {
        case GET_COMPANY_BUBBLES:
        case SEARCH_BUBBLES:
            return { ...state, loading: true, error: '' };
        case SEARCH_BUBBLES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                searchResults: action.bubbles,
            };
        case GET_COMPANY_BUBBLES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                companyBubbles: action.bubbles,
            };
        case SEARCH_BUBBLES_FAILED:
        case GET_COMPANY_BUBBLES_FAILED:
            return { ...state, loading: false, error: action.error.message };
        default:
            return state;
    }
};

export default bubble;

import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Route, Redirect, Switch, useLocation,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Pusher from 'pusher-js/with-encryption';
import { useTranslation } from 'react-i18next';

import { ChatContext } from './contexts';

import UserService from './api/userService';

import Wrapper from './hoc/Wrapper';
import ProtectedRoute from './hoc/ProtectedRoute';

import { message as MessageType } from './redux/types/chat';
import { State as reduxState } from './redux/reducers/rootReducer';
import { addMessageNotification } from './redux/actions/chat';
import { enqueueSnackbar } from './redux/actions/notification';

import WelcomePage from './pages/Welcome';
import InfoPage from './pages/Info';
import LoginPage from './pages/Login';
import Mybubbles from './pages/Mybubbles';
import Register from './pages/Register';
import EditBubbles from './pages/EditBubbles';
import Climate from './pages/Climate';
import Hub from './pages/Hub';
import Chat from './pages/Chat';
import Insights from './pages/Insights';
import ChatOverview from './pages/ChatOverview';
import Account from './pages/Account';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Verify from './pages/Verify';
import RegisteredSuccess from './pages/RegisteredSuccess';
import ForgotPasswordConfirm from './pages/ForgotPasswordConfirm';
import Error from './pages/Error';
import TermsAndConditions from './pages/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'

const App: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const [messages, setMessages] = useState<MessageType[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [error, setError] = useState('');

    const myUserId = useSelector((state: reduxState) => state.user.user.pk);
    const notifications = useSelector((state: reduxState) => state.chat.notifications);
    const activeChat = useSelector((state: reduxState) => state.chat.active);
    const activeBubble = useSelector((state: reduxState) => state.user.active);

    const fetchChat = useCallback(async (activeChatPK: number, activeBubbleID: number) => {
        try {
            setLoading(true);
            const messageData = await UserService.getChat(activeChatPK, activeBubbleID);

            setMessages(messageData);
            setLoading(false);
        } catch (err) {
            setError(err.message);
        }
    }, []);

    const addMessage = (data: MessageType) => setMessages((prevState) => ([...prevState, data]));

    const clearChat = () => setMessages([]);

    const sendmessage = async (text: string) => {
        try {
            if (text) {
                setLoadingMessage(true);
                const data = await UserService.sendMessage(activeChat.pk, activeBubble.id, text);
                addMessage(data.message);
                setLoadingMessage(false);
            }
        } catch (err) {
            setLoadingMessage(false);
            console.error(err);
            setError(err);
            dispatch(enqueueSnackbar({
                message: t('error.general'),
                options: {
                    variant: 'error',
                    key: new Date().getTime() + Math.random(),
                },
            }));
        }
    };

    const handleNotifcations = async (message: MessageType) => {
        // Check if this message is for me
        if (myUserId && message.recipient_id === myUserId) {
            if (activeBubble.id === message.bubble_id && activeChat.pk === message.sender_id) {
                addMessage(message);
                try {
                    await UserService.markMessageAsRead(activeBubble.id, myUserId);
                } catch (err) {
                    console.error(error);
                }
            }
            // Check if there is already a notification from this chat (senderId & bubbleId)
            const sameNotification = notifications.find(({ sender_id, bubble_id }) => sender_id === message.sender_id && bubble_id === message.bubble_id);
            if (!sameNotification) {
                // Check if active chat is not already open
                if (pathname !== '/chat') {
                    dispatch(addMessageNotification(message));
                } else if (activeChat.pk !== message.sender_id || message.bubble_id !== activeBubble.id) {
                    dispatch(addMessageNotification(message));
                }
            }
        }
    };

    useEffect(() => {
        const channelName = 'bubblechat';

        // Enable pusher logging - don't include this in production
        // Pusher.logToConsole = false;

        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || '', {
            cluster: 'eu',
        });

        const channel = pusher.subscribe(channelName);

        channel.bind('test', (data: MessageType) => {
            handleNotifcations(data);
        });

        return () => {
            pusher.unsubscribe(channelName);
            pusher.disconnect();
        };
        // eslint-disable-next-line
    }, [pathname, activeChat.pk, activeBubble.id]);

    return (
        <ChatContext.Provider value={ {
            messages,
            loading,
            loadingMessage,
            error,
            sendmessage,
            fetchChat,
            clearChat,
        } }
        >
            <SnackbarProvider
                maxSnack={ 1 }
                autoHideDuration={ 3000 }
                anchorOrigin={ {
                    vertical: 'bottom',
                    horizontal: 'center',
                } }
            >
                <Wrapper>
                    <Switch>
                        <Route path="/" exact component={ WelcomePage } />
                        <Route path="/info" exact component={ InfoPage } />
                        <Route path="/login" exact component={ LoginPage } />
                        <Route path="/registreren" exact component={ Register } />
                        <Route path="/registreren-success" exact component={ RegisteredSuccess } />
                        <Route path="/wachtwoord-vergeten" exact component={ ForgotPassword } />
                        <Route path="/activeren" exact component={ Verify } />
                        <Route path="/wachtwoord-vergeten-bevestigen" exact component={ ForgotPasswordConfirm } />
                        <Route path="/algemene-voorwaarden" exact component={ TermsAndConditions } />
                        <Route path="/privacy-beleid" exact component={ PrivacyPolicy } />
                        <ProtectedRoute path="/wachtwoord-resetten" exact component={ ResetPassword } />
                        <ProtectedRoute path="/mijn-bubbls" exact component={ Mybubbles } />
                        <ProtectedRoute path="/bubbls" exact component={ EditBubbles } />
                        <ProtectedRoute path="/klimaat" exact component={ Climate } />
                        <ProtectedRoute path="/chat" exact component={ Chat } />
                        <ProtectedRoute path="/hub" exact component={ Hub } />
                        <ProtectedRoute path="/ups/:bubbleId" exact component={ Insights } />
                        <ProtectedRoute path="/chats" exact component={ ChatOverview } />
                        <ProtectedRoute path="/account" exact component={ Account } />
                        <Route path="/404" component={ Error } />
                        <Redirect to="/404" />
                    </Switch>
                </Wrapper>
            </SnackbarProvider>
        </ChatContext.Provider>
    );
};

export default App;

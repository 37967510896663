import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState, option as OptionType } from '../redux/types/app';
import { UserState } from '../redux/types/user';
import { setShowModal } from '../redux/actions/app';

import Navigation from '../components/Navigation';
import Modal from '../components/Modal';
import Notifier from '../components/Notifier';

interface Props {
    children: React.ReactNode;
    backgroundColor: AppState['backgroundColor'];
    showNav: AppState['showNav'];
    modal: AppState['modal'];
    active: UserState['active'];
    setShowModalFunc: (show: boolean, option: OptionType) => void;
}

const Wrapper: React.FC<Props> = ({
    children, backgroundColor: bgClass, showNav, modal, setShowModalFunc, active,
}) => {
    const newModalProps = {
        active,
    };

    return (
        <div
            style={ { zIndex: 0 } }
            className={ `min-h-full flex flex-col relative ${bgClass} ${showNav && 'pb-32'}` }
        >
            { modal.show && <Modal modalProps={ newModalProps } option={ modal.option } onClick={ (show: boolean) => setShowModalFunc(show, '') } /> }
            { children }
            { showNav && <Navigation /> }
            <Notifier />
        </div>
    );
};

interface State {
    app: AppState;
    user: UserState;
}

const mapStateToProps = ({ app, user }: State) => ({
    ...app,
    ...user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setShowModalFunc: (show: boolean, option: OptionType) => dispatch(setShowModal(show, option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);

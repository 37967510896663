import { NotificationState, NotificationTypes } from '../types/notification';
import {
    ENQUEUE_SNACKBAR,
    REMOVE_SNACKBAR,
    CLOSE_SNACKBAR
} from '../constants/notifications';

const initialState: NotificationState = {
    notifications: [],
};

const notificationReducer = (state = initialState, action: NotificationTypes): NotificationState => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    action.notification,
                ],
            };
        case CLOSE_SNACKBAR:
            if (action.dismissAll) {
                return {
                    ...state,
                    notifications: state.notifications.map((notification) => ({ ...notification, dismissed: true })),
                };
            }
            return {
                ...state,
                notifications: state.notifications.map((notification) => {
                    if (notification.options.key === action.key) return { ...notification, dismissed: true };
                    return notification;
                }),
            };
        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.options.key !== action.key,
                ),
            };
        default:
            return state;
    }
};

export default notificationReducer;

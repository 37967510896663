import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../components/Header';
import Terms from '../components/Terms';
import RegisterForm from '../components/RegisterForm';

// import Bubble from '../components/Bubble';

import { AppState } from '../redux/types/app';
import { UserState, registerInputs } from '../redux/types/user';

import { setBackground } from '../redux/actions/app';
import { register } from '../redux/actions/user';

interface Props {
    loading: UserState['loading'];
    error: UserState['error'];
    setBackgroundFunc: (background: AppState['backgroundColor']) => void
    backgroundColor: AppState['backgroundColor']
    registerFunc: (data: registerInputs, onFinish: () => void) => void;
}

const Register: React.FC<Props> = ({
    backgroundColor,
    setBackgroundFunc,
    loading,
    registerFunc,
    error,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if (backgroundColor !== 'bg-p-1') setBackgroundFunc('bg-p-1');
    });

    return (
        <>
            <Header onClickOne={ () => history.goBack() } pageTitle={ t('user.register') } />
            {/* <div className="mx-auto my-3">
                <Bubble
                    onClick={ () => {
                        history.push('/info');
                    } }
                    size={ 0 }
                    className="bg-p-1 gradient-border"
                />
            </div> */}
            <RegisterForm
                onRegister={ registerFunc }
                onFinish={ () => history.push('/registreren-success') }
                error={ error }
                loading={ loading }
            />
            <Terms />
            <div className="text-grey text-center pb-5 justify-center items-end flex-grow md:flex-grow-0 flex px-6 mt-5 md:w-6/12 xl:w-3/12  md:mx-auto">
                { t('user.registeredAlready') }
                <button
                    className="terms-btn pl-1"
                    type="button"
                    onClick={ () => history.push('/login') }
                >
                    { t('user.login') }
                </button>
            </div>
        </>
    );
};

interface State {
    app: AppState
    user: UserState
}

const mapStateToProps = ({ app, user }: State) => ({
    ...app,
    ...user,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    registerFunc: (data: registerInputs, onFinish: () => void) => dispatch(register(data, onFinish)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

import React from 'react';
import Placeholder from '../svg/user-placeholder.jpg';
import { imagePrefix } from '../constants/global';

interface Props {
    imageSrc?: string;
    onChange: (files: FileList | null) => void;
}

const ImageUpload: React.FC<Props> = ({ imageSrc, onChange }) => {
    const handleOnChange = (files: FileList | null) => onChange(files);

    return (
        <div className="w-full">
            <label htmlFor="myFile" className="w-32 h-32 bg-white bg-cover bg-center bg-no-repeat block mx-auto rounded-full relative gradient-border" style={ { backgroundImage: imageSrc ? `url(${imagePrefix}${imageSrc})` : `url(${Placeholder})` } }>
                {/* <img
                    alt="BubbleHub Profiel Foto"
                    className="w-full h-full object-cover rounded-full"
                    src={ imageSrc ? `${imagePrefix}${imageSrc}` : Placeholder }
                /> */}
            </label>
            <input onChange={ (e) => handleOnChange(e.target.files) } className="hidden" id="myFile" type="file" name="file" />
        </div>
    );
};

export default ImageUpload;

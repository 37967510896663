import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';

// Import translations
import './i18n';

import store, { persistor } from './redux/store';

import './tailwind.output.css';

import Loader from './components/Loader';

const LoadingComponent = () => <div className="flex flex-grow items-center justify-center"><Loader /></div>;

const el = document.getElementById('root');

if (el) {
    ReactDOM.render(
        <Provider store={ store }>
            <PersistGate loading={ <LoadingComponent /> } persistor={ persistor }>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>, el,
    );
}

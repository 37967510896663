import React from 'react';

import { ReactComponent as Xcircle } from '../svg/delete-circle.svg';

interface Props {
    name: string;
    className?: string;
    onClose: () => void;
}

const SelectedBubble: React.FC<Props> = ({
    name,
    onClose,
    className,
}) => (
    <div className={ `rounded-full inline-block mr-3 mb-3 px-3 py-1 fade-in ${className}` }>
        <div className="flex">
            <span>
                { name }
            </span>
            <button onClick={ onClose } className="pl-5" type="button">
                <Xcircle className="w-5" />
            </button>
        </div>
    </div>
);

export default SelectedBubble;

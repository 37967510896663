import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import { minimumCharactersPassword } from '../constants/global';

import { UserState, loginInputs } from '../redux/types/user';

import Loader from './Loader';

interface Props {
    loading: UserState['loading'];
    error: UserState['error'];
    loginFunc: (data: loginInputs) => void;
}

const schema = yup.object().shape({
    email: yup.string().email(i18n.t('form.email.valid')).required(i18n.t('form.email.required')),
    password: yup.string().required(i18n.t('form.password.required')).min(minimumCharactersPassword, i18n.t('form.password.minimumCharacters', { count: minimumCharactersPassword })),
});

const LoginForm: React.FC<Props> = ({
    loading,
    loginFunc,
    error,
}) => {
    const {
        register,
        handleSubmit,
        errors,
    } = useForm<loginInputs>({ resolver: yupResolver(schema) });

    const { t } = useTranslation();

    const onSubmit = (data: loginInputs) => {
        loginFunc(data);
    };

    return (
        <form className="fade-in px-6 mt-4 mb-4 flex flex-col container relative" onSubmit={ handleSubmit(onSubmit) }>
            { loading && <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"><Loader className="z-10" /></div> }
            <span className={ `input-wrapper gradient-border ${loading && 'opacity-25'}` }>
                <input className="input bg-p-2 focus:outline-none" name="email" placeholder={ t('user.email') } ref={ register() } />
            </span>
            <span className="mb-2 pl-5">
                { errors.email?.message }
            </span>
            <span className={ `input-wrapper gradient-border ${loading && 'opacity-25'}` }>
                <input className="relative gradient-border input bg-p-2 focus:outline-none" placeholder={ t('user.password') } name="password" type="password" ref={ register() } />
            </span>
            <span className="mb-2 pl-5">
                { errors.password?.message }
            </span>
            <button className={ `btn focus:outline-none bg-g-1 ${loading && 'opacity-25'}` } disabled={ loading } type="submit">{ t('button.login') }</button>
            { error && <span className="text-red-500">{ error }</span> }
        </form>
    );
};

export default LoginForm;

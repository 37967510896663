import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppState } from '../redux/types/app';

import Header from '../components/Header';
import Bubble from '../components/Bubble';
import TextLogo from '../components/TextLogo';

import { setBackground, setShowNav } from '../redux/actions/app';

interface Props {
    setBackgroundFunc: (background: string) => void;
    backgroundColor: AppState['backgroundColor'];
    showNav: AppState['showNav'];
}

const WelcomePage: React.FC<Props> = ({ setBackgroundFunc, backgroundColor, showNav }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (backgroundColor !== 'bg-p-1') setBackgroundFunc('bg-p-1');
        if (showNav) dispatch(setShowNav(false));
    });

    return (
        <>
            <Header pageTitle={ t('page.titles.welcome') } />
            <TextLogo />
            <div className="fade-in primary-bg welcome-container pt-8 relative flex-grow mx-auto w-11/12 md:w-7/12 lg:w-5/12 xl:w-3/12 max-w-full">
                <Bubble
                    onClick={ () => {
                        history.push('/info');
                    } }
                    size={ 0 }
                    text={ t('user.info') }
                    className="bg-p-1 gradient-border"
                    placement={ { right: 0 } }
                />
                <Bubble
                    onClick={ () => history.push('registreren') }
                    size={ 0.5 }
                    className="bg-p-1 gradient-border"
                    text={ t('user.register') }
                    placement={ { left: 0, top: 138 } }
                />
                <Bubble
                    onClick={ () => {
                        history.push('/login');
                    } }
                    size={ 1 }
                    className="bg-p-1 gradient-border"
                    text={ t('user.login') }
                    placement={ { right: -30, top: 250 } }
                />
            </div>
        </>
    );
};

interface State {
    app: AppState
}

const mapStateToProps = ({ app }: State) => ({
    ...app,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);

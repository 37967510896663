import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { message as MessageType, ChatState } from '../redux/types/chat';
import { ReactComponent as CheckIcon } from '../svg/check.svg';
import Loader from './Loader';
import Placeholder from '../svg/user-placeholder.jpg';
import { imagePrefix } from '../constants/global';

interface Props {
    messages: MessageType[];
    recipient: ChatState['active'];
    className: string | undefined;
    loading: boolean;
}

const ChatUI: React.FC<Props> = ({
    messages,
    recipient,
    className = '',
    loading,
}) => {
    const { t } = useTranslation();

    const divEl = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (divEl) {
            divEl!.current!.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    });

    const renderLoader = () => (
        <div
            className="pb-2 mr-4 flex justify-end"
        >
            <Loader className="w-8" />
        </div>
    );

    const renderMessages = () => {
        if (messages && messages.length) {
            return messages.map(({
                message, recipient_id, id, date_send,
            }) => {
                if (recipient_id === recipient.pk) {
                    const d = new Date(date_send);
                    return (
                        <div
                            className="pb-5 flex justify-end"
                            key={ id }
                        >
                            <div
                                className={ `${className} sent-message mr-4 rounded-lg rounded-br-none pt-3` }
                            >
                                <div className="px-3">{ message }</div>
                                <div className="text-right mr-3">
                                    <span className="text-xs pl-2">{ `${date_send ? `${d.getHours()}: ${d.getMinutes()}` : ''}` }</span>
                                    <CheckIcon className="w-4 inline-block ml-2" />
                                </div>
                            </div>
                        </div>
                    );
                }
                return (
                    <div
                        className="pb-2 flex justify-start items-end ml-3"
                        key={ id }
                    >
                        <img
                            alt="BubbleHub profiel foto"
                            className="rounded-full w-10 h-10 object-cover"
                            src={ recipient.picture.url ? `${imagePrefix}${recipient.picture.url}` : Placeholder }
                        />
                        <div
                            className={ `${className} received-message mr-4 ml-5 rounded-lg rounded-bl-none pt-3 pb-2` }
                        >
                            <div className="px-3">{ message }</div>
                        </div>
                    </div>
                );
            });
        }
        if (messages && !messages.length) {
            return (
                <div
                    className="pb-2 mr-4 text-center py-20 text-gray-500 opacity-75"
                >
                    { t('chat.start', { name: recipient.name })}
                </div>
            );
        }
        return null;
    };

    return (
        <div className="overflow-y-scroll 162 chat-container mb-24 mt-20">
            { renderMessages() }
            { loading && renderLoader() }
            <div id="el" ref={ divEl } />
        </div>
    );
};

export default ChatUI;

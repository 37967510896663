import React from 'react';

import { ReactComponent as LoaderSpinner } from '../svg/loader.svg';

interface Props {
    className?: string
}

const Loader: React.FC<Props> = ({ className }) => (
    <div className={ className }>
        <LoaderSpinner className="animate-spin" />
    </div>
);

export default Loader;

import {
    SEARCH_BUBBLES,
    SEARCH_BUBBLES_FAILED,
    SEARCH_BUBBLES_SUCCESS,
    GET_COMPANY_BUBBLES,
    GET_COMPANY_BUBBLES_FAILED,
    GET_COMPANY_BUBBLES_SUCCESS,
} from '../constants/bubble';
import { BubbleTypes } from '../types/bubble';
import { bubble as BubbleType } from '../types/user';

export const getCompanyBubbles = (): BubbleTypes => ({
    type: GET_COMPANY_BUBBLES,
});

export const getCompanySuccess = (bubbles: BubbleType[]): BubbleTypes => ({
    type: GET_COMPANY_BUBBLES_SUCCESS,
    bubbles,
});

export const getCompanyFailed = (error: Error): BubbleTypes => ({
    type: GET_COMPANY_BUBBLES_FAILED,
    error,
});

export const searchBubbles = (query: string): BubbleTypes => ({
    type: SEARCH_BUBBLES,
    query,
});

export const searchBubblesSuccess = (bubbles: BubbleType[]): BubbleTypes => ({
    type: SEARCH_BUBBLES_SUCCESS,
    bubbles,
});

export const searchBubblesFailed = (error: Error): BubbleTypes => ({
    type: SEARCH_BUBBLES_FAILED,
    error,
});

import { combineReducers, CombinedState } from 'redux';
import storage from 'redux-persist/lib/storage';

import app from './app';
import user from './user';
import bubble from './bubble';
import chat from './chat';
import notification from './notification';

import { AppState, AppTypes } from '../types/app';
import { BubbleState, BubbleTypes } from '../types/bubble';
import { UserState, UserTypes } from '../types/user';
import { ChatState, ChatTypes } from '../types/chat';
import { NotificationState, NotificationTypes } from '../types/notification';

const appReducer = combineReducers({
    app,
    user,
    bubble,
    chat,
    notification,
});

export interface State {
    app: AppState;
    user: UserState;
    bubble: BubbleState;
    chat: ChatState;
    notification: NotificationState;
}

const rootReducer = (state: State | undefined, action: AppTypes | BubbleTypes | UserTypes | ChatTypes | NotificationTypes): CombinedState<State> => {
    if (action.type === 'USER/LOG_OUT') {
        // TODO: remove redux persist state
        storage.removeItem('persist:root');
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default rootReducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Error: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <>
            <div className="px-5 text-center flex-grow md:flex-grow-0 flex md:h-20 justify-center items-center text-red-500">
                { t('error.notFound') }
            </div>
            <button onClick={ () => history.push('/bubbls') } className="btn bg-g-1 mx-5" type="submit">{ t('error.goback') }</button>
        </>
    );
};

export default Error;

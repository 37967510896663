import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR, CLOSE_SNACKBAR } from '../constants/notifications';
import { NotificationTypes, notification as NotificationType } from '../types/notification';

export const enqueueSnackbar = (notification: NotificationType): NotificationTypes => ({
    type: ENQUEUE_SNACKBAR,
    notification,
});

export const closeSnackbar = (key: NotificationType['options']['key']): NotificationTypes => ({
    type: CLOSE_SNACKBAR,
    dismissAll: !key,
    key,
});

export const removeSnackbar = (key: NotificationType['options']['key']): NotificationTypes => ({
    type: REMOVE_SNACKBAR,
    key,
});

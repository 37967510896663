import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppState } from '../redux/types/app';
import { UserState } from '../redux/types/user';
import { insight as InsightType } from '../redux/types/bubble';

import Loader from '../components/Loader';
import Insight from '../components/Insight';

import UserService from '../api/userService';

import Header from '../components/Header';

import { setBackground, setShowNav } from '../redux/actions/app';

interface Props {
    setBackgroundFunc: (background: AppState['backgroundColor']) => void;
    backgroundColor: AppState['backgroundColor'];
    active: UserState['active'];
    setShowNavFunc: (show: AppState['showNav']) => void;
    showNav: AppState['showNav'];
    user: UserState['user'];
}

const Insights: React.FC<Props> = ({
    setBackgroundFunc,
    setShowNavFunc,
    showNav,
    backgroundColor,
    active,
    user,
}) => {
    const history = useHistory();

    const { t } = useTranslation();
    const { bubbleId } = useParams();
    const [insights, setInsights] = useState<InsightType[]>([]);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState<string>('');
    const [, setError] = useState('');

    const fetchInsights = async () => {
        setLoading(true);
        try {
            const insightsData = await UserService.getBubbleInsights(bubbleId);
            setLoading(false);
            setInsights(insightsData);
            return insightsData;
        } catch (err) {
            setLoading(false);
            setError(err.message);
        }
    };

    const likeInsight = async (index: number, id: number, add: boolean) => {
        if (add) {
            try {
                await UserService.likeInsight(active.id, id);
                const newInsights = [...insights];
                newInsights[index].liked_by.push(user);
                newInsights[index].likes_count += 1;
                setInsights(newInsights);
            } catch (err) {
                setError(err.message);
            }
        } else {
            try {
                await UserService.unlikeInsight(active.id, id);
                const newInsights = [...insights];
                newInsights[index].liked_by = newInsights[index].liked_by.filter(({ pk }) => pk !== user.pk);
                newInsights[index].likes_count -= 1;
                setInsights(newInsights);
            } catch (err) {
                setError(err.message);
            }
        }
    };

    useEffect(() => {
        if (!bubbleId) history.push('/mijn-bubbls');
        if (active.className && backgroundColor !== active.className) setBackgroundFunc(active.className);
        if (!showNav) setShowNavFunc(true);
        fetchInsights();
        // eslint-disable-next-line
    }, []);

    const renderInsights = () => {
        if (loading) return <div className="flex flex-grow items-center justify-center"><Loader /></div>;

        if (insights && insights.length) {
            return (
                <div className="divide-y divide-gray-400 divide-opacity-25 fade-in">
                    {
                        insights.map((ins, index) => {
                            if (ins.title.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
                                return <Insight onClick={ (add) => likeInsight(index, ins.id, add) } user={ user } className={ `${active.className}-t` } insight={ ins } key={ index } />;
                            }
                            return null;
                        })
                    }
                </div>
            );
        }

        if (insights && !insights.length) {
            return <div className="flex flex-grow items-center justify-center">{ t('bubbles.empty') }</div>;
        }

        return null;
    };

    const renderHeader = () => {
        if (!loading && insights && insights.length) {
            return (
                <>
                    <Header
                        onChange={ setQuery }
                        value={ query }
                        subTextClassName="text-gray-400"
                        subText={ `${insights.length} ${t('bubbles.insights')}` }
                        onClickOne={ () => history.goBack() }
                        pageTitle={ insights[0].bubble.name }
                    />
                    <div className="pl-6 py-4 text-gray-400">
                        <span className="uppercase">{ t('bubbles.likes') }</span>
                    </div>
                </>
            );
        }
        return (
            <>
                <Header
                    onClickOne={ () => history.goBack() }
                    pageTitle=" "
                />
            </>
        );
    };

    return (
        <>
            { renderHeader() }
            { renderInsights() }
        </>
    );
};

interface State {
    app: AppState;
    user: UserState;
}

const mapStateToProps = ({ app, user }: State) => ({
    ...app,
    ...user,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Insights);

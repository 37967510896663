import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { UserState, registerInputs } from '../redux/types/user';

import Loader from './Loader';
import ImageUpload from './ImageUpload';

interface Props {
    loading: UserState['loading'];
    error: UserState['error'];
    onFinish: () => void;
    onRegister: (data: registerInputs, onFinish: () => void) => void;
}

const minimumCharactersPassword = 5;

const schema = yup.object().shape({
    name: yup.string().required('Uw naam is verplicht'),
    email: yup.string().email('Vul een geldig email adres in').required('Uw e-mail is verplicht'),
    password: yup.string().required('Uw wachtwoord is verplicht').min(minimumCharactersPassword, `Uw wachtwoord moet minimaal ${minimumCharactersPassword} tekens bevatten`),
});

const RegisterForm: React.FC<Props> = ({
    loading,
    error,
    onRegister,
    onFinish,
}) => {
    const {
        register,
        handleSubmit,
        errors,
    } = useForm<registerInputs>({ resolver: yupResolver(schema) });
    const fd = new FormData();
    const [formData] = useState<FormData>(fd);

    const { t } = useTranslation();

    // const customHandler = async (data: registerInputs) => {
    //     formData.append('email', data.email);
    //     formData.append('name', data.name);
    //     formData.append('password', data.password);
    //     await fetch('http://soekhoe.pythonanywhere.com/api/user/signup/', {
    //         method: 'POST',
    //         body: formData,
    //     });
    // };

    const onSubmit = (data: registerInputs) => {
        // customHandler(data);
        onRegister(data, onFinish);
    };

    const onChangeHandler = (files: FileList | null) => {
        if (files && files.length) {
            formData.append('file', files[0]);
        }
    };

    return (
        <>
            <ImageUpload
                onChange={ onChangeHandler }
            />
            <form className="fade-in px-6 mt-4 mb-8 flex flex-col w-full md:w-6/12 xl:w-3/12  md:mx-auto relative" onSubmit={ handleSubmit(onSubmit) }>
                { loading && <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"><Loader className="z-10" /></div> }
                <span className={ `input-wrapper gradient-border ${loading && 'opacity-25'}` }>
                    <input className="input bg-p-2 focus:outline-none" name="name" placeholder={ t('user.name') } ref={ register() } />
                </span>
                { errors.name?.message && (
                    <span className="mb-2 pl-5">
                        { errors.name?.message }
                    </span>
                ) }
                <span className={ `input-wrapper gradient-border ${loading && 'opacity-25'}` }>
                    <input className="input bg-p-2 focus:outline-none" name="email" placeholder={ t('user.email') } ref={ register() } />
                </span>
                { errors.email?.message && (
                    <span className="mb-2 pl-5">
                        { errors.email?.message }
                    </span>
                ) }
                <span className={ `input-wrapper gradient-border ${loading && 'opacity-25'}` }>
                    <input className="relative gradient-border input bg-p-2 focus:outline-none" placeholder={ t('user.password') } name="password" type="password" ref={ register() } />
                </span>
                { errors.password?.message && (
                    <span className="mb-2 pl-5">
                        { errors.password?.message }
                    </span>
                ) }
                <button className={ `btn bg-g-1 focus:outline-none ${loading && 'opacity-25'}` } type="submit">{ t('button.register') }</button>
                { error && <span className="text-red-500">{ error }</span> }
            </form>
        </>
    );
};

export default RegisterForm;

import FetchService from './fetchService';
import store from '../redux/store';
import { logOut } from '../redux/actions/user';
import { loginInputs, registerInputs } from '../redux/types/user';
import i18n from '../i18n';

class UserService extends FetchService {
    getAuthHeader = () => ({ Authorization: `Bearer ${super.getAccessToken()}` });

    getJSONHeader = () => ({ 'Content-Type': 'application/json' });

    logOutOn401 = (response: Response) => {
        if (response.status === 401) {
            store.dispatch(logOut());
            throw new Error(i18n.t('general.expired'));
        }
    }

    async login({ email, password }: loginInputs) {
        try {
            const response = await super.fetch('/user/login/', 'POST', {
                email,
                password,
            });

            const data = await response.json();

            if (response.status !== 200) {
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async register({ email, password, name }: registerInputs) {
        try {
            const response = await super.fetch('/user/signup/', 'POST', {
                email,
                password,
                name,
            });

            if (response.status !== 200 && response.status !== 201) throw new Error(i18n.t('error.exists'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async verify(user_pk: string, token: string) {
        try {
            const response = await super.fetch('/user/verify_email/', 'POST', {
                user_pk,
                token,
            });

            const data = await response.json();

            if (response.status !== 200) {
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async forgotPassword(email: string) {
        try {
            const response = await super.fetch('/user/password_reset/', 'POST', {
                email,
            });

            const data = await response.json();

            if (response.status !== 200) {
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async leaveBubble(pk: string | undefined) {
        if (!pk) throw new Error('pk undefined');

        try {
            const response = await super.fetch(`/bubble/exit/${pk}/`, 'PUT', {}, {
                headers: {
                    ...this.getAuthHeader(),
                },
            });
            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 204) throw new Error(i18n.t('error.general'));
        } catch (error) {
            throw new Error(error);
        }
    }

    async getBubbles() {
        try {
            const response = await super.fetch('/bubble/personal_list/', 'GET', {}, {
                headers: {
                    ...this.getJSONHeader(),
                    ...this.getAuthHeader(),
                },
            });

            this.logOutOn401(response);

            if (response.status !== 200) throw new Error(i18n.t('error.general'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async getCompanyBubbles() {
        try {
            const response = await super.fetch('/bubble/', 'GET', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });

            this.logOutOn401(response);

            if (response.status !== 200) throw new Error(i18n.t('error.general'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async searchBubbles(query: string) {
        try {
            const response = await super.fetch(`/bubble/?search=${query}`, 'GET', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });

            this.logOutOn401(response);

            const data = await response.json();

            if (response.status !== 200) {
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            //If the user did not query, then return the bubbles in a quasi-random order (instead of biggest to smallest)
            if(query){
              return data;
            }else{
              return data.sort(() => Math.random() - 0.5);
            }

        } catch (error) {
            throw new Error(error);
        }
    }

    async createBubble(name: string) {
        try {
            const response = await super.fetch('/bubble/createorjoin/', 'POST', {
                name,
            },
            {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            const data = await response.json();

            if (response.status !== 200 && response.status !== 201) {
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async getBubbleMembers(id: number) {
        try {
            const response = await super.fetch(`/bubble/${id}/employee_list/`, 'GET', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 201) throw new Error(i18n.t('error.general'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async getChat(recipientId: number, bubbleId: number) {
        try {
            const response = await super.fetch('/chat/conversations/', 'POST', {
                bubble_id: bubbleId,
                recipient_id: recipientId,
            }, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 201) throw new Error(i18n.t('error.general'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async getAllChats() {
        try {
            const response = await super.fetch('/chat/recentConversations/', 'POST', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            this.logOutOn401(response);

            const data = await response.json();

            if (response.status !== 200) {
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async sendMessage(recipientId: number, bubbleId: number, message: string) {
        try {
            const response = await super.fetch('/chat/conversation/', 'POST', {
                bubble_id: bubbleId,
                recipient_id: recipientId,
                message,
            }, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            this.logOutOn401(response);

            const data = await response.json();

            if (response.status !== 200) {
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async markMessageAsRead(bubble_id: number, recipient_id: number) {
        try {
            const response = await super.fetch('/chat/updatestatus/', 'POST', {
                bubble_id,
                recipient_id,
            }, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 201) throw new Error(i18n.t('error.general'));

            return response;
        } catch (error) {
            throw new Error(error);
        }
    }

    async getBubbleInsights(BubbleId: number) {
        try {
            const response = await super.fetch(`/bubble/${BubbleId}/insight/`, 'GET', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 201) throw new Error(i18n.t('error.general'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async likeInsight(bubbleId: number, insightId: number) {
        try {
            const response = await super.fetch(`/bubble/${bubbleId}/insight/${insightId}/like/`, 'GET', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 201) throw new Error(i18n.t('error.general'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async unlikeInsight(bubbleId: number, insightId: number) {
        try {
            const response = await super.fetch(`/bubble/${bubbleId}/insight/${insightId}/unlike/`, 'GET', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 204) throw new Error(i18n.t('error.general'));

            return '';
        } catch (error) {
            throw new Error(error);
        }
    }

    async deleteAccount() {
        try {
            const response = await super.fetch('/account/delete', 'POST', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });
            this.logOutOn401(response);

            const data = await response.json();

            if (response.status !== 200) {
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async resetPassword(password: string, newPassword: string) {
        try {
            const response = await super.fetch('/account/password', 'POST', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });

            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 201) throw new Error(i18n.t('error.general'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async getMyInsights(bubbleId: number) {
        try {
            const response = await super.fetch(`/bubble/${bubbleId}/insight/?search=user`, 'GET', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });

            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 201) throw new Error(i18n.t('error.general'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async addInsight(bubbleId: number, title: string) {
        try {
            const response = await super.fetch(`/bubble/${bubbleId}/insight/`, 'POST', {
                title,
                description: title,
            }, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });

            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 201) throw new Error(i18n.t('error.general'));

            return await response.json();
        } catch (error) {
            throw new Error(error);
        }
    }

    async deleteInsight(bubbleId: number, insightId: number) {
        try {
            const response = await super.fetch(`/bubble/${bubbleId}/insight/${insightId}`, 'DELETE', {}, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });

            this.logOutOn401(response);

            if (response.status !== 200 && response.status !== 204) throw new Error(i18n.t('error.general'));
        } catch (error) {
            throw new Error(error);
        }
    }

    async changeName(name: string) {
        try {
            const response = await super.fetch('/user/details/', 'PATCH', {
                name,
            }, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });

            this.logOutOn401(response);

            const data = await response.json();

            if (response.status !== 200 && response.status !== 201) {
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async changePassword(oldPass: string, newPass: string, newPass2: string) {
        try {
            const response = await super.fetch('/user/password/change/', 'POST', {
                old_password: oldPass,
                new_password1: newPass,
                new_password2: newPass2,
            }, {
                headers: {
                    ...this.getAuthHeader(),
                    ...this.getJSONHeader(),
                },
            });

            this.logOutOn401(response);

            const data = await response.json();

            if (response.status !== 200 && response.status !== 201) {
                if (data.old_password && data.old_password.length) throw new Error(data.old_password[0]);
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async forgotPasswordConfirm(password: string, token: string) {
        try {
            const response = await super.fetch('/user/password_reset/confirm/', 'POST', {
                password,
                token,
            }, {
                headers: {
                    ...this.getJSONHeader(),
                },
            });

            const data = await response.json();

            if (response.status !== 200 && response.status !== 201) {
                if (data.password && data.password.length) throw new Error(data.password[0]);
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }

    async fileUpload(formdata: FormData) {
        try {
            const response = await super.fileUpload(formdata);

            this.logOutOn401(response);

            const data = await response.json();

            if (response.status !== 200 && response.status !== 201) {
                if (data.new_password2 && data.new_password2.length) throw new Error(data.new_password2[0]);
                if (data.detail) throw new Error(data.detail);
                if (data.message) throw new Error(data.message);
                throw new Error(i18n.t('error.general'));
            }

            return data;
        } catch (error) {
            throw new Error(error);
        }
    }
}

export default new UserService();

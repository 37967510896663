import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { myBubbleColorOrder } from '../constants/global';

import UserService from '../api/userService';
import { State as reduxState } from '../redux/reducers/rootReducer';

import { AppState } from '../redux/types/app';
import {
    UserState, bubble as BubbleType, member as MemberType, chatBubble,
} from '../redux/types/user';
import {
    chat as ChatType,
    message as MessageType,
} from '../redux/types/chat';

import Header from '../components/Header';
import SmallBubble from '../components/SmallBubble';
import Loader from '../components/Loader';
import SingleChat from '../components/SingleChat';

import { setActiveChat, addRecentConvoNotifications } from '../redux/actions/chat';
import { setBackground, setShowNav } from '../redux/actions/app';
import { setActiveBubble } from '../redux/actions/user';

type colorType = {
    [key: number]: string;
}

interface Props {
    setBackgroundFunc: (background: AppState['backgroundColor']) => void;
    backgroundColor: AppState['backgroundColor'];
    setShowNavFunc: (show: AppState['showNav']) => void;
    showNav: AppState['showNav'];
}

const ChatOverview: React.FC<Props> = ({
    setBackgroundFunc,
    setShowNavFunc,
    showNav,
    backgroundColor,
}) => {
    const history = useHistory();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notifications = useSelector((state: reduxState) => state.chat.notifications);
    const [chats, setChats] = useState<ChatType[]>([]);
    const [colors, setColors] = useState<colorType | undefined>(undefined);
    const [activeBubbleFilter, setActiveBubbleFilter] = useState<number>(0);
    const [chatBubbles, setChatBubbles] = useState<chatBubble[]>([]);
    const [loadingChats, setLoadingChats] = useState<boolean>(false);
    const [myBubblesLoading, setMyBubblesLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const setActiveBubbleFunc = (bubble: BubbleType, className: string, onFinish: () => void) => dispatch(setActiveBubble(bubble, className, onFinish));
    const setActiveChatFunc = (member: MemberType) => dispatch(setActiveChat(member));

    const fetchChatMembers = useCallback(async () => {
        try {
            setLoadingChats(true);
            const allChats = await UserService.getAllChats();
            if (allChats && Array.isArray(allChats) && allChats.length) {
                setChats(allChats);
                const newNotifications: MessageType[] = [];
                allChats.forEach(({ notification, bubble, chat_with_user }: ChatType) => {
                    if (notification) {
                        newNotifications.push({
                            message: '',
                            bubble_id: bubble.id,
                            id: '',
                            sender_id: chat_with_user.pk,
                            date_send: '',
                            recipient_id: 0,
                        });
                    }
                });
                if (newNotifications && newNotifications.length) dispatch(addRecentConvoNotifications(newNotifications));
            } else {
                setChats([]);
            }
            setLoadingChats(false);
        } catch (err) {
            setError(err.message);
            setLoadingChats(false);
        }
    // eslint-disable-next-line
    }, []);

    const getBubblesFunc = useCallback(async () => {
        try {
            setMyBubblesLoading(true);
            const bubbles = await UserService.getBubbles();
            setChatBubbles(bubbles);
            setMyBubblesLoading(false);
        } catch (err) {
            setError(err.message);
            setMyBubblesLoading(false);
        }
    }, []);

    useEffect(() => {
        if (backgroundColor !== 'bg-p-2') setBackgroundFunc('bg-p-2');
        if (!showNav) setShowNavFunc(true);
        if (!chatBubbles || !chatBubbles.length) getBubblesFunc();
        if ((colors && Object.keys(colors).length) && (!chats || !chats.length)) fetchChatMembers();
        // eslint-disable-next-line
    }, [colors]);

    const onSmallBubbleClick = (bubble: BubbleType) => {
        if (activeBubbleFilter === bubble.id) {
            setActiveBubbleFilter(0);
        } else {
            setActiveBubbleFilter(bubble.id);
        }
    };

    const renderSmallBubbles = () => {
        if (myBubblesLoading) return <div className="flex justify-center p-2"><Loader /></div>;

        if (chatBubbles && chatBubbles.length) {
            if (!colors) {
                const newColors = chatBubbles.reduce((acc, cur, idx) => ({
                    ...acc,
                    [cur.id]: myBubbleColorOrder[idx],
                }), {});
                setColors(newColors);
            }
            return (
                <div className="pl-6 overflow-x-scroll mb-1">
                    { chatBubbles.map((bub, index) => <SmallBubble activeId={ activeBubbleFilter } notification={ !!notifications.find(({ bubble_id }) => bubble_id === bub.id) } onClick={ onSmallBubbleClick } className={ `${myBubbleColorOrder[index]} mr-2` } bubble={ bub } key={ `${index}-${bub.name}` } />) }
                </div>
            );
        }

        return null;
    };

    const onMemberClick = (chat: ChatType):void => {
        const { chat_with_user: member, bubble } = chat;
        const onFinish = () => history.push('/chat');
        setActiveChatFunc(member);
        setActiveBubbleFunc(bubble, colors ? colors[bubble.id] : 'bubble-bg-4', onFinish);
    };

    const renderMembers = () => {
        if (loadingChats) return <div className="flex justify-center p-2"><Loader /></div>;

        if (chats && chats.length && colors) {
            if (activeBubbleFilter) {
                return (
                    <div className="divide-y divide-gray-400 divide-opacity-25">
                        {
                            chats.filter(({ bubble }) => bubble.id === activeBubbleFilter).map((chat, index) => (
                                <SingleChat
                                    notification={ !!notifications.find(({ bubble_id, sender_id }) => sender_id === chat.chat_with_user.pk && bubble_id === chat.bubble.id) }
                                    onClick={ onMemberClick }
                                    key={ `${index}-${chat.bubble.name}-${chat.chat_with_user.pk}` }
                                    className={ `${colors[chat.bubble.id]}` }
                                    chat={ chat }
                                />
                            ))
                        }
                    </div>
                );
            }
            return (
                <div className="divide-y divide-gray-400 divide-opacity-25">
                    {
                        chats.map((chat, index) => (
                            <SingleChat
                                notification={ !!notifications.find(({ bubble_id, sender_id }) => sender_id === chat.chat_with_user.pk && bubble_id === chat.bubble.id) }
                                onClick={ onMemberClick }
                                key={ `${index}-${chat.bubble.name}-${chat.chat_with_user.pk}` }
                                className={ `${colors[chat.bubble.id]}` }
                                chat={ chat }
                            />
                        ))
                    }
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <Header
                pageTitle={ t('page.titles.chats') }
            />
            <div className="pl-6 pb-2 pt-3 text-gray-400">
                <span className="uppercase">{ t('page.titles.hub') }</span>
            </div>
            { renderSmallBubbles() }
            <div className="pl-6 pb-4 pt-3 text-gray-400">
                <span className="uppercase">{ t('chat.recent') }</span>
            </div>
            { renderMembers() }
            { error && <span className="container text-center text-red-500 block mb-4">{ error }</span> }
        </>
    );
};

interface State {
    app: AppState;
    user: UserState;
}

const mapStateToProps = ({ app, user }: State) => ({
    ...app,
    myBubbles: user.bubbles,
    myBubblesLoading: user.loading,
    activeUser: user.user,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatOverview);

import { UserState, UserTypes } from '../types/user';
import {
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    LOG_OUT,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_FAILED,
    DELETE_ACCOUNT_SUCCESS,
    REGISTER,
    REGISTER_FAILED,
    REGISTER_SUCCESS,
    LEAVE_BUBBLE,
    LEAVE_BUBBLE_FAILED,
    LEAVE_BUBBLE_SUCCESS,
    GET_BUBBLES,
    GET_BUBBLES_FAILED,
    GET_BUBBLES_SUCCESS,
    JOIN_BUBBLE,
    JOIN_BUBBLE_FAILED,
    JOIN_BUBBLE_SUCCESS,
    CREATE_BUBBLE,
    CREATE_BUBBLE_FAILED,
    CREATE_BUBBLE_SUCCESS,
    SET_ACTIVE_BUBBLE,
    GET_BUBBLE_MEMBERS,
    GET_BUBBLE_MEMBERS_FAILED,
    GET_BUBBLE_MEMBERS_SUCCESS,
    CHANGE_NAME,
    CHANGE_NAME_FAILED,
    CHANGE_NAME_SUCCESS,
    CHANGE_PHOTO,
    CHANGE_PHOTO_FAILED,
    CHANGE_PHOTO_SUCCESS,
} from '../constants/user';

const initialState: UserState = {
    loading: false,
    error: '',
    accessToken: '',
    refreshToken: '',
    bubbles: [],
    active: {
        id: 0,
        name: '',
        company: '',
        user_count: 0,
        className: 'bubble-bg-4',
    },
    user: {
        pk: 0,
        email: '',
        name: '',
        picture: {
            id: 0,
            url: '',
        },
    },
};

const user = (state = initialState, action: UserTypes): UserState => {
    switch (action.type) {
        case LOGIN:
        case REGISTER:
        case LEAVE_BUBBLE:
        case GET_BUBBLES:
        case JOIN_BUBBLE:
        case CREATE_BUBBLE:
        case DELETE_ACCOUNT:
        case GET_BUBBLE_MEMBERS:
        case CHANGE_PHOTO:
        case CHANGE_NAME:
            return { ...state, loading: true, error: '' };
        case SET_ACTIVE_BUBBLE:
            return {
                ...state,
                error: '',
                active: {
                    ...action.bubble,
                    className: action.className,
                },
            };
        case CHANGE_NAME_SUCCESS:
        case CHANGE_PHOTO_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                user: action.user,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                user: action.user,
            };
        case LEAVE_BUBBLE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                bubbles: state.bubbles.filter(({ id }) => id !== action.id),
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
            };
        case JOIN_BUBBLE_SUCCESS:
        case CREATE_BUBBLE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                bubbles: [...state.bubbles, action.bubble],
            };
        case GET_BUBBLES_SUCCESS:
            if (action.bubbles) {
                return {
                    ...state,
                    loading: false,
                    error: '',
                    bubbles: action.bubbles,
                };
            }
            return {
                ...state,
                loading: false,
                error: '',
            };
        case LOG_OUT:
        case DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                accessToken: '',
                refreshToken: '',
            };
        case GET_BUBBLE_MEMBERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                active: {
                    ...state.active,
                    members: action.members,
                },
            };
        case LOGIN_FAILED:
        case REGISTER_FAILED:
        case LEAVE_BUBBLE_FAILED:
        case GET_BUBBLES_FAILED:
        case JOIN_BUBBLE_FAILED:
        case CREATE_BUBBLE_FAILED:
        case GET_BUBBLE_MEMBERS_FAILED:
        case DELETE_ACCOUNT_FAILED:
        case CHANGE_NAME_FAILED:
        case CHANGE_PHOTO_FAILED:
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};

export default user;

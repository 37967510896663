import React from 'react';
import { bubble as BubbleType, chatBubble } from '../redux/types/user';

interface Props {
    bubble: chatBubble;
    className?: string;
    notification?: boolean;
    onClick: (bubble: BubbleType) => void;
    activeId: number;
}

const SmallBubble: React.FC<Props> = ({
    bubble, className, notification = false, onClick, activeId,
}) => {
    const acronym = bubble.name.split(/\s/).reduce((acc, word, index, arr) => {
        if (index < 2) {
            const arrayLength = arr.length;

            if ((index + 1) === arrayLength) return `${acc}${word.slice(0, 1)}`;
            return `${acc}${word.slice(0, 1)}.`;
        }
        return acc;
    }, '');
    return (
        <button
            onClick={ () => onClick(bubble) }
            type="button"
            className={ `focus:outline-none fade-in rounded-full mt-2 relative w-10 h-10 uppercase ${className} ${activeId === bubble.id ? 'border-2 border-white border-solid' : ''}` }
        >
            {
                notification && (
                    <span className="absolute right-0 top-0 inline-flex rounded-full h-3 w-3 bg-teal-400">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-teal-400 opacity-75" />
                    </span>
                )
            }
            { acronym }
        </button>
    );
};

export default SmallBubble;

import {
    SET_ACTIVE_CHAT,
    SEND_MESSAGE,
    SEND_MESSAGE_FAILED,
    SEND_MESSAGE_SUCCESS,
    ADD_MESSAGE_NOTIFICATION,
    REMOVE_MESSAGE_NOTIFICATION,
    ADD_RECENT_CONVO_NOTIFICATIONS,
} from '../constants/chat';
import { member as MemberType } from '../types/user';
import { ChatTypes, message as MessageType } from '../types/chat';

export const addRecentConvoNotifications = (notifications: MessageType[]): ChatTypes => ({
    type: ADD_RECENT_CONVO_NOTIFICATIONS,
    notifications,
});

export const addMessageNotification = (message: MessageType): ChatTypes => ({
    type: ADD_MESSAGE_NOTIFICATION,
    message,
});

export const removeMessageNotification = (sender_id: MessageType['sender_id'], bubble_id: MessageType['bubble_id']): ChatTypes => ({
    type: REMOVE_MESSAGE_NOTIFICATION,
    sender_id,
    bubble_id,
});

export const setActiveChat = (member: MemberType): ChatTypes => ({
    type: SET_ACTIVE_CHAT,
    member,
});

export const sendMessage = (message: string, bubble_id: string, recipient_id: string): ChatTypes => ({
    type: SEND_MESSAGE,
    message,
    bubble_id,
    recipient_id,
});

export const sendMessageSuccess = (message: string): ChatTypes => ({
    type: SEND_MESSAGE_SUCCESS,
    message,
});

export const sendMessageFailed = (error: string): ChatTypes => ({
    type: SEND_MESSAGE_FAILED,
    error,
});

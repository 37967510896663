import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { UserState } from '../redux/types/user';

interface Props extends RouteProps {
    accessToken: string;
    component: RouteProps['component'] | any;
}

export const ProtectedRoute: React.FC<Props> = ({ component: Component, accessToken, ...rest }) => (
    <Route { ...rest } render={ (props) => (accessToken ? <Component { ...props } /> : <Redirect to="/login" />) } />
);

interface State {
    user: UserState;
}

const mapStateToProps = ({ user }: State) => ({ accessToken: user.accessToken });

export default connect(mapStateToProps)(ProtectedRoute);

import React from 'react';
import Placeholder from '../svg/user-placeholder.jpg';
import { chat as ChatType } from '../redux/types/chat';
import { showDate } from '../utils/chat';
import { ReactComponent as ClockIcon } from '../svg/clock.svg';
import { imagePrefix } from '../constants/global';

interface Props {
    chat: ChatType;
    className?: string;
    onClick: (chat: ChatType) => void;
    notification?: boolean;
}

const SingleChat: React.FC<Props> = ({
    chat, className, onClick, notification,
}) => {
    const {
        chat_with_user: member, bubble, date_message,
    } = chat;
    const { url } = member.picture;

    return (
        <button
            type="button"
            onClick={ () => onClick(chat) }
            className="focus:outline-none fade-in flex w-full py-4 items-stretch px-6"
        >
            <div className="mr-6 w-10 h-10 relative rounded-full bg-cover bg-center bg-no-repeat" style={ { backgroundImage: url ? `url(${imagePrefix}${url})` : `url(${Placeholder})` } }>
                {
                    notification && (
                        <span className="absolute member-notification top-0 inline-flex rounded-full h-3 w-3 bg-teal-400">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-teal-400 opacity-75" />
                        </span>
                    )
                }
                {/* <img
                    alt="BubbleHub profiel foto"
                    className="rounded-full w-10 h-10 object-cover"
                    src={ member.picture.url ? `${imagePrefix}${member.picture.url}` : Placeholder }
                /> */}
            </div>
            <div className="text-left flex-grow">
                <p>
                    { member.name }
                </p>
                <span className={ `${className}-t` }>
                    { bubble.name }
                </span>
            </div>
            <div className="flex items-start">
                <span className="pr-3">
                    { showDate(date_message) }
                </span>
                <span>
                    <ClockIcon className="pt-1" />
                </span>
            </div>
        </button>
    );
};

export default SingleChat;

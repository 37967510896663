import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar, SnackbarKey } from 'notistack';
import { useTranslation } from 'react-i18next';
import { removeSnackbar } from '../redux/actions/notification';
import { State } from '../redux/reducers/rootReducer';

let displayed: SnackbarKey[] = [];

const Notifier: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notifications = useSelector((store: State) => store.notification.notifications || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id: string | number) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: string | number) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(({
            message, options, dismissed = false,
        }) => {
            const { key, action } = options;

            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                ...options,
                ...(action ? {} : { action: (snackKey: SnackbarKey) => <button type="button" onClick={ () => closeSnackbar(snackKey) }>{ t('error.dismiss') }</button> }),
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t]);

    return null;
};

export default Notifier;

import React, {
    useEffect, useContext,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { ReactComponent as InzichtIcon } from '../svg/ups-symbol.svg';
import { UserState } from '../redux/types/user';
import { ChatState } from '../redux/types/chat';
import { AppState, option as OptionType } from '../redux/types/app';
import { ChatContext } from '../contexts';
import { removeMessageNotification } from '../redux/actions/chat';

import { setBackground, setShowNav, setShowModal } from '../redux/actions/app';

import Header from '../components/Header';
import ChatBox from '../components/ChatBox';
import ChatUI from '../components/ChatUI';
import Loader from '../components/Loader';

interface Props {
    activeChat: ChatState['active'];
    setBackgroundFunc: (backgroundColor: AppState['backgroundColor']) => void;
    setShowModalFunc: (show: boolean, option: OptionType) => void;
    backgroundColor: AppState['backgroundColor'];
    setShowNavFunc: (show: AppState['showNav']) => void;
    showNav: AppState['showNav'];
    bubbleClassName: string | undefined;
    bubbleName: string;
    currentBubbleId: number;
    currentUserId: number;
}

const Chat: React.FC<Props> = ({
    activeChat,
    setShowNavFunc,
    setBackgroundFunc,
    setShowModalFunc,
    showNav,
    backgroundColor,
    bubbleClassName,
    bubbleName,
    currentBubbleId,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        messages, loading, loadingMessage,
        sendmessage, fetchChat, clearChat,
    } = useContext(ChatContext);

    useEffect(() => {
        if (!activeChat.pk || !currentBubbleId) history.push('/mijn-bubbls');
        dispatch(removeMessageNotification(activeChat.pk, currentBubbleId));
        if (backgroundColor !== 'bg-p-2') setBackgroundFunc('bg-p-2');
        if (showNav) setShowNavFunc(false);

        if (fetchChat) fetchChat(activeChat.pk, currentBubbleId);
        // TODO: fetchChat();
        return () => {
            if (clearChat) clearChat();
        };
        // eslint-disable-next-line
    }, [activeChat.pk, currentBubbleId]);

    return (
        <>
            <Header
                Element={ (
                    <button
                        onClick={ () => setShowModalFunc(true, 'insights') }
                        className="absolute header-element focus:outline-none right-0 pr-3 md:pr-5 hover:opacity-75"
                        type="submit"
                    >
                        {/* <InzichtIcon className="w-8" /> @TODO @TEMPORARY LET OP: dit is het up-icoon */}
                    </button>
                ) }
                subText={ bubbleName }
                subTextClassName={ `${bubbleClassName}-t` }
                className="bg-p-2"
                fixed
                upperCase={ false }
                onClickOne={ () => history.goBack() }
                pageTitle={ activeChat.name }
            />
            { loading ? (
                <div className="flex-grow flex justify-center items-center"><Loader /></div>
            ) : (
                <ChatUI
                    loading={ loadingMessage }
                    className={ bubbleClassName }
                    recipient={ activeChat }
                    messages={ messages }
                />
            ) }
            <ChatBox
                onSend={ (text) => {
                    if (sendmessage) sendmessage(text);
                } }
            />
        </>
    );
};

interface State {
    app: AppState;
    user: UserState;
    chat: ChatState;
}

const mapStateToProps = ({ app, user, chat }: State) => ({
    ...app,
    bubbleClassName: user.active.className,
    currentUserId: user.user.pk,
    bubbleName: user.active.name,
    currentBubbleId: user.active.id,
    activeChat: chat.active,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
    setShowModalFunc: (show: boolean, option: OptionType) => dispatch(setShowModal(show, option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

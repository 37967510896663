import React from 'react';
import { insight as InsightTypes } from '../redux/types/bubble';
import { UserState } from '../redux/types/user';

import { ReactComponent as ThumbUp } from '../svg/thumbs-up-lined.svg';
import { ReactComponent as ThumbUpFilled } from '../svg/thumbs-up-filled.svg';

interface Props {
    insight: InsightTypes;
    className?: string;
    user: UserState['user'];
    onClick: (add: boolean) => void;
}

const Insight: React.FC<Props> = ({
    insight, className, user, onClick,
}) => {
    const isLiked = insight.liked_by.find(({ pk }) => pk === user.pk);
    const handleClick = () => {
        if (isLiked) {
            onClick(false);
        } else {
            onClick(true);
        }
    };

    return (
        <div className="flex pl-4 py-5">
            <button onClick={ handleClick } type="button" className="focus:outline-none flex-shrink-0 flex justify-center items-center pr-4">
                { isLiked ? <ThumbUpFilled className="w-8" /> : <ThumbUp className="w-8" /> }
                <span className={ `${className} pl-2` }>{ insight.likes_count }</span>
            </button>
            <div className="flex-grow flex items-center pr-3">
                { insight.title }
            </div>
        </div>
    );
};

export default Insight;

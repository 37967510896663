import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppState } from '../redux/types/app';
import { BubbleState } from '../redux/types/bubble';
import { bubble as BubbleType } from '../redux/types/user';

import Header from '../components/Header';
import Loader from '../components/Loader';
import Bubble from '../components/Bubble';

import { myBubbleLocations, myBubbleColorOrder, myBubbleSizes } from '../constants/global';

import { setBackground, setShowNav } from '../redux/actions/app';
import { getCompanyBubbles } from '../redux/actions/bubble';
import { setActiveBubble } from '../redux/actions/user';

interface Props {
    setBackgroundFunc: (background: AppState['backgroundColor']) => void;
    backgroundColor: AppState['backgroundColor'];
    setShowNavFunc: (show: AppState['showNav']) => void;
    showNav: AppState['showNav'];
    companyBubbles: BubbleState['companyBubbles'];
    getCompanyBubblesFunc: () => void;
    setActiveBubbleFunc: (bubble: BubbleType, className: string, onFinish: () => void) => void;
    loading: BubbleState['loading'];
}

const Hub: React.FC<Props> = ({
    setBackgroundFunc,
    setShowNavFunc,
    getCompanyBubblesFunc,
    setActiveBubbleFunc,
    backgroundColor,
    showNav,
    companyBubbles,
    loading,
}) => {
    const history = useHistory();

    const { t } = useTranslation();

    useEffect(() => {
        if (backgroundColor !== 'bg-p-2') setBackgroundFunc('bg-p-2');
        if (!showNav) setShowNavFunc(true);
        getCompanyBubblesFunc();
        // eslint-disable-next-line
    }, []);

    const renderBubbles = () => {
        if (loading) return <div className="flex flex-grow items-center justify-center"><Loader /></div>;
        if (companyBubbles && companyBubbles.length) {
            return companyBubbles.slice(0, 7).map((bub, index) => (
                <Bubble
                    key={ `${bub.name}-${index}` }
                    text={ bub.name }
                    className={ myBubbleColorOrder[index] }
                    size={ myBubbleSizes[index] }
                    placement={ myBubbleLocations[index] }
                    onClick={ () => {
                        setBackgroundFunc(myBubbleColorOrder[index]);
                        setActiveBubbleFunc(bub, myBubbleColorOrder[index], () => history.push(`/ups/${bub.id}`));
                    } }
                />
            ));
        }
        return null;
    };

    return (
        <>
            <Header pageTitle={ t('page.titles.hub') } />
            <div className="my-bubbles-container fade-in flex flex-grow flex-col md:flex-grow-0 container relative">
                { renderBubbles() }
            </div>
        </>
    );
};

interface State {
    app: AppState;
    bubble: BubbleState;
}

const mapStateToProps = ({ app, bubble }: State) => ({
    ...app,
    ...bubble,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    setShowNavFunc: (show: boolean) => dispatch(setShowNav(show)),
    getCompanyBubblesFunc: () => dispatch(getCompanyBubbles()),
    setActiveBubbleFunc: (bubble: BubbleType, className: string, onFinish: () => void) => dispatch(setActiveBubble(bubble, className, onFinish)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hub);

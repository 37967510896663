import store from '../redux/store';
import { BodyTypes } from './serviceTypes';

export const defaultOptions = {
    HEAD: {
        method: 'HEAD',
    },
    PUT: {
        method: 'PUT',
    },
    GET: {
        method: 'GET',
    },
    POST: {
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        method: 'POST',
    },
    PATCH: {
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        method: 'PATCH',
    },
    DELETE: {
        method: 'DELETE',
    },
};

export const baseURL = process.env.NODE_ENV === 'production' ? 'https://bubblhub.appspot.com/api' : 'https://bubblhub.appspot.com/api';

export default class FetchService {
    /**
   *
   * @param {string} url endpoint to call
   * @param {string} type request type automatically includes default request options
   * @param {object} customOptions options object to override default options
   * @param {object} body form body object
   */

    fetch(url = '', type: keyof typeof defaultOptions = 'GET', body: BodyTypes, customOptions = {}): Promise<Response> {
        const fetchBody = type === 'GET' ? undefined : {
            ...body,
        };
        const options = {
            ...defaultOptions[type],
            headers: new Headers({
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${this.getAccessToken()}`,
            }),
            ...customOptions,
            body: JSON.stringify(fetchBody),
        };
        const endpoint = `${baseURL}${url}`;
        return fetch(endpoint, options);
    }

    fileUpload(body: FormData): Promise<Response> {
        const endpoint = `${baseURL}/user/picture/upload/`;
        const options = {
            method: 'POST',
            headers: {
                // 'Content-Type': 'multipart/form-data;',
                Authorization: `Bearer ${this.getAccessToken()}`,
            },
            body,
        };
        return fetch(endpoint, options);
    }

    getAccessToken():string {
        return store.getState().user.accessToken;
    }
}

import { Dispatch, MiddlewareAPI } from 'redux';
import { AppTypes } from '../types/app';
import { BubbleTypes } from '../types/bubble';
import { UserTypes } from '../types/user';
import { ChatTypes } from '../types/chat';
import { NotificationTypes } from '../types/notification';
import { enqueueSnackbar } from '../actions/notification';
import { CHANGE_PHOTO_SUCCESS, LOG_OUT, REGISTER_SUCCESS } from '../constants/user';
import i18n from '../../i18n';

const pushNotification = (store: MiddlewareAPI, action: any) => {
    if (action.type.toLocaleLowerCase().includes('failed') && action.error) {
        store.dispatch(enqueueSnackbar({
            message: action.error,
            options: {
                variant: 'error',
                key: new Date().getTime() + Math.random(),
            },
        }));
    }
};

const notification = (store: MiddlewareAPI) => (next: Dispatch) => (action: BubbleTypes | AppTypes | UserTypes | ChatTypes | NotificationTypes): Promise<void> | BubbleTypes | AppTypes | UserTypes | ChatTypes | NotificationTypes | undefined => {
    switch (action.type) {
        case REGISTER_SUCCESS:
        case CHANGE_PHOTO_SUCCESS:
            store.dispatch(enqueueSnackbar({
                message: i18n.t('general.success'),
                options: {
                    variant: 'success',
                    key: new Date().getTime() + Math.random(),
                },
            }));
            return next(action);
        case LOG_OUT:
            next(action);
            store.dispatch(enqueueSnackbar({
                message: i18n.t('user.loggedOut'),
                options: {
                    variant: 'success',
                    key: new Date().getTime() + Math.random(),
                },
            }));
            break;
        default:
            pushNotification(store, action);
            return next(action);
    }
};

export default notification;

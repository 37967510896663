import React, { useState, useRef, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { bubble } from '../redux/types/user';

import Suggestion from './Suggestion';

import Loader from './Loader';

import { ReactComponent as SearchIcon } from '../svg/Search.svg';
import { ReactComponent as DeleteIcon } from '../svg/delete-halfrounded.svg';

interface Props {
    onChange: (query: string) => void;
    suggestions: bubble[];
    loading: boolean;
    onSuggestionClick: (id: string) => void;
    onNoResultsClick: (name: string) => void;
    onResetSuggestions: () => void;
}

const Search: React.FC<Props> = ({
    onChange,
    suggestions,
    onSuggestionClick,
    onNoResultsClick,
    onResetSuggestions,
    loading,
}) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const [doneTyping, setDoneTyping] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [query, setQuery] = useState('');

    const inputEl = useRef() as MutableRefObject<HTMLInputElement>;

    const debouncer = (qry: string) => setTimeout(() => {
        onChange(qry);
        setDoneTyping(true);
    }, 1200);

    const handleChange = (searchQuery: string) => {
        setQuery(searchQuery);
        setDoneTyping(false);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setTypingTimeout(debouncer(searchQuery));
    };

    const renderSuggestions = () => {
        if (loading) {
            return <div className="flex items-center h-20 justify-center"><Loader /></div>;
        }

        if (suggestions && suggestions.length) {
            return suggestions.map((sug, index) => (
                <Suggestion
                    onClick={ () => {
                        onSuggestionClick(sug.name);
                        onResetSuggestions();
                        setQuery('');
                        setIsActive(false);
                    } }
                    sug={ sug }
                    key={ index }
                />
            ));
        }

        if (!loading && doneTyping && !suggestions?.length && query.length > 0) {
            return (
                <button
                    onClick={ () => {
                        onNoResultsClick(query);
                        onResetSuggestions();
                        setQuery('');
                        setIsActive(false);
                    } }
                    className="btn mt-5 bg-g-1 block mx-auto"
                    type="submit"
                >
                    { t('button.add') }
                </button>
            );
        }

        return null;
    };

    return (
        <>
            <div className={ `z-30 flex flex-row bg-s-7 relative ${isActive ? 'pt-3 pl-4 pr-1 border-b-2 border-white' : 'mt-3 rounded-full mx-5'}` }>
                <input
                    ref={ inputEl }
                    className="flex-grow focus:outline-none bg-transparent text-white p-2 pl-4 pr-0"
                    placeholder={ t('general.add') }
                    name={ Math.random().toString(36).substring(7) }
                    value={ query }
                    onFocus={ () => setIsActive(true) }
                    onChange={ (e) => handleChange(e.target.value) }
                />
                {
                    (isActive && query) ? (
                        <button
                            className="focus:outline-none"
                            onClick={ () => {
                                handleChange('');
                                onResetSuggestions();
                                inputEl.current.focus();
                            } }
                            type="button"
                        >
                            <DeleteIcon className="focus:outline-none w-6 mr-4" />
                        </button>
                    ) : (
                        <button className="focus:outline-none" type="button">
                            <SearchIcon className="focus:outline-none w-6 mr-4" />
                        </button>
                    )
                }
                { isActive && (
                    <div className="bg-s-7 suggestions left-0 right-0 absolute w-full overflow-scroll text-white">
                        { renderSuggestions() }
                    </div>
                ) }
            </div>
            { /* eslint-disable-next-line */
                isActive && ( <div role="button"
                    onClick={ () => {
                        setIsActive(false);
                        // onResetSuggestions();
                        setQuery('');
                    } }
                    className="z-20 bg-black opacity-50 absolute top-0 right-0 left-0 bottom-0"
                />
                )
            }
        </>
    );
};

export default Search;

import React, {
    useState, useRef, MutableRefObject, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowLeft } from '../svg/arrow-left.svg';
import { ReactComponent as SearchIcon } from '../svg/white-search.svg';
import { ReactComponent as DeleteIcon } from '../svg/delete-halfrounded.svg';

interface Props {
    pageTitle: string;
    className?: string;
    fixed?: boolean;
    subText?: string;
    subTextClassName?: string;
    upperCase?: boolean;
    onClickOne?: () => void;
    onChange?: any;
    value?: number | string;
    Element?: React.ReactNode;
    LeftElement?: React.ReactNode;
}

const Header: React.FC<Props> = ({
    pageTitle, upperCase = true, onClickOne, Element, value,
    fixed, className, subText, subTextClassName, onChange,
    LeftElement,
}) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const inputEl = useRef() as MutableRefObject<HTMLInputElement>;
    const { t } = useTranslation();

    useEffect(() => {
        if (onChange && isActive && inputEl) inputEl.current.focus();
    }, [onChange, isActive, inputEl]);

    const renderLeftSide = () => {
        if (LeftElement) return LeftElement;
        if (onClickOne) {
            return (
                <button
                    onClick={ onClickOne }
                    className="absolute header-arrow left-0 pl-1 md:pl-5 hover:opacity-75"
                    type="button"
                >
                    <ArrowLeft className="w-10" />
                </button>
            );
        }
        return null;
    };

    const renderRightSide = () => {
        if (onChange) {
            const onButtonClick = () => {
                if (!isActive) {
                    return setIsActive(true);
                }
                onChange('');
                return setIsActive(false);
            };

            return (
                <button
                    onClick={ onButtonClick }
                    className="absolute header-element focus:outline-none right-0 pr-3 md:pr-5 hover:opacity-75"
                    type="submit"
                >
                    {
                        isActive ? (
                            <DeleteIcon className="w-8 fill-current text-white" />
                        ) : (
                            <SearchIcon className="w-8 fill-current text-white" />
                        )
                    }
                </button>
            );
        }
        if (Element) return Element;
        return null;
    };

    const renderInput = () => {
        if (onChange && isActive) {
            return (
                <input
                    onChange={ (e) => onChange(e.target.value) }
                    value={ value }
                    onBlur={ () => {
                        onChange('');
                        setIsActive(false);
                    } }
                    ref={ inputEl }
                    placeholder={ t('general.search') }
                    className="bg-transparent text-white w-full py-3 px-4 focus:outline-none z-10 border-solid border-2 border-white border-opacity-50"
                />
            );
        }
        return null;
    };

    return (
        <>
            <div className={ `flex justify-between px-1 md:px-5 h-16 md:h-20 items-center ${className} ${fixed ? 'fixed top-0 left-0 right-0' : ''}` }>
                <div className="flex-grow text-center">
                    { renderLeftSide() }
                    <div>
                        <span className="pt-10">{ upperCase ? pageTitle.toUpperCase() : pageTitle }</span>
                        { subText && (
                            <>
                                <br />
                                <span className={ `text-xs ${subTextClassName ? subTextClassName : ''}` }>{ subText }</span>
                            </>
                        ) }
                    </div>
                    { renderRightSide() }
                </div>
            </div>
            { renderInput() }
        </>
    );
};

export default Header;

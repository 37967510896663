import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { State as reduxState } from '../redux/reducers/rootReducer';

import { AppState } from '../redux/types/app';
import { UserState, loginInputs } from '../redux/types/user';

import { login } from '../redux/actions/user';

import Header from '../components/Header';
import TextLogo from '../components/TextLogo';
import LoginForm from '../components/LoginForm';
import Terms from '../components/Terms';

import { setBackground, setShowNav } from '../redux/actions/app';
import { enqueueSnackbar } from '../redux/actions/notification';

interface Props {
    loading: UserState['loading'];
    error: UserState['error'];
    loginFunc: (data: loginInputs, onFinish: () => void) => void;
    setBackgroundFunc: (background: AppState['backgroundColor']) => void;
    setShowNavFunc: (show: AppState['showNav']) => void;
    backgroundColor: AppState['backgroundColor'];
    showNav: AppState['showNav'];
}

const Login: React.FC<Props> = ({
    setBackgroundFunc,
    setShowNavFunc,
    backgroundColor,
    loading,
    loginFunc,
    error,
    showNav,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const accessToken = useSelector((state: reduxState) => state.user.accessToken);

    const { t } = useTranslation();

    useEffect(() => {
        if (accessToken) history.push('/bubbls');
        if (backgroundColor !== 'bg-p-1') setBackgroundFunc('bg-p-1');
        if (showNav) setShowNavFunc(false);
    });

    const handleLogin = (data: loginInputs) => {
        const onFinish = () => {
            history.push('/bubbls');
            dispatch(enqueueSnackbar({
                message: t('user.loggedIn'),
                options: {
                    variant: 'success',
                    key: new Date().getTime() + Math.random(),
                },
            }));
        };

        loginFunc(data, onFinish);
    };

    return (
        <>
            <Header onClickOne={ () => history.push('/') } pageTitle={ t('page.titles.login') } />
            <TextLogo />
            <LoginForm
                loading={ loading }
                error={ error }
                loginFunc={ handleLogin }
            />
            <Terms />
            <div className="text-grey pb-10 items-end flex-grow md:flex-grow-0 flex px-6 mt-8 md:w-6/12 xl:w-3/12  md:mx-auto">
                <div className="text-left flex-1">
                    <button
                        type="button"
                        onClick={ () => history.push('/wachtwoord-vergeten') }
                    >
                        WACHTWOORD
                        <br />
                        VERGETEN?
                    </button>
                </div>
                <div className="text-right flex-1">
                    <button
                        type="button"
                        onClick={ () => history.push('/registreren') }
                    >
                        ACCOUNT
                        <br />
                        AANMAKEN
                    </button>
                </div>
            </div>
        </>
    );
};

interface State {
    app: AppState
    user: UserState
}

const mapStateToProps = ({ app, user }: State) => ({
    ...app,
    ...user,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
    loginFunc: (data: loginInputs, onFinish: () => void) => dispatch(login(data, onFinish)),
    setShowNavFunc: (show: AppState['showNav']) => dispatch(setShowNav(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

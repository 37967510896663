import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppState } from '../redux/types/app';

import Header from '../components/Header';
import TextLogo from '../components/TextLogo';

import { setBackground, setShowNav } from '../redux/actions/app';

interface Props {
    setBackgroundFunc: (background: string) => void;
    backgroundColor: AppState['backgroundColor'];
    showNav: AppState['showNav'];
}

const TermsAndConditions: React.FC<Props> = ({ setBackgroundFunc, backgroundColor, showNav }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (backgroundColor !== 'bg-p-1') setBackgroundFunc('bg-p-1');
        if (showNav) dispatch(setShowNav(false));
    });

    return (
        <>
            <Header
                onClickOne={ () => history.goBack() }
                pageTitle={ t('account.terms') } />
            <TextLogo />
            <div className="flex-grow justify-center mx-3 mb-8 p-5 pr-8 overflow-scroll h-1" style={{color: 'black', background: 'white',borderRadius: '20px'}}>
              <b style={{fontSize: '20px'}}>{ t('account.terms') }</b>
              <br/>
              { t('terms.intro') }
              <br/>
              <br/>
              <b>{ t('terms.article_1.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_1.content.item_1') }</li>
                <li>{ t('terms.article_1.content.item_2') }</li>
              </ol>
              <br/>
              <b>{ t('terms.article_2.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_2.content.item_1') }</li>
                <li>{ t('terms.article_2.content.item_2') }</li>
                <li>{ t('terms.article_2.content.item_3') }</li>
                <li>{ t('terms.article_2.content.item_4') }</li>
              </ol>
              <br/>
              <b>{ t('terms.article_3.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_3.content.item_1') }</li>
                <li>
                  { t('terms.article_3.content.item_2.title') }
                  <ul>
                    <li className="list-disc list-inside pl-3">{ t('terms.article_3.content.item_2.content.item_1') }</li>
                    <li className="list-disc list-inside pl-3">{ t('terms.article_3.content.item_2.content.item_2') }</li>
                    <li className="list-disc list-inside pl-3">{ t('terms.article_3.content.item_2.content.item_3') }</li>
                    <li className="list-disc list-inside pl-3">{ t('terms.article_3.content.item_2.content.item_4') }</li>
                    <li className="list-disc list-inside pl-3">{ t('terms.article_3.content.item_2.content.item_5') }</li>
                    <li className="list-disc list-inside pl-3">{ t('terms.article_3.content.item_2.content.item_6') }</li>
                  </ul>
                </li>
              </ol>
              <br/>
              <b>{ t('terms.article_4.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_4.content.item_1') }</li>
              </ol>
              <br/>
              <b>{ t('terms.article_5.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_5.content.item_1') }</li>
                <li>{ t('terms.article_5.content.item_2') }</li>
              </ol>
              <br/>
              <b>{ t('terms.article_6.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_6.content.item_1') }</li>
                <li>{ t('terms.article_6.content.item_2') }</li>
              </ol>
              <br/>
              <b>{ t('terms.article_7.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_7.content.item_1') }</li>
                <li>{ t('terms.article_7.content.item_2') }</li>
              </ol>
              <br/>
              <b>{ t('terms.article_8.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_8.content.item_1') }</li>
                <li>{ t('terms.article_8.content.item_2') }</li>
                <li>{ t('terms.article_8.content.item_3') }</li>
                <li>{ t('terms.article_8.content.item_4') }</li>
              </ol>
              <br/>
              <b>{ t('terms.article_9.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_9.content.item_1') }</li>
              </ol>
              <br/>
              <b>{ t('terms.article_10.title') }</b>
              <ol className="list-decimal pl-4">
                <li>{ t('terms.article_10.content.item_1') }</li>
                <li>{ t('terms.article_10.content.item_2') }</li>
              </ol>
            </div>
        </>
    );
};

interface State {
    app: AppState
}

const mapStateToProps = ({ app }: State) => ({
    ...app,
});

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
    setBackgroundFunc: (background: string) => dispatch(setBackground(background)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Terms: React.FC = () => {
    const history = useHistory();

    const { t } = useTranslation();

    return (
        <div className="text-center text-grey">
            { t('general.termsAgree') }
            <br />
            {' '}
            <button
                className="terms-btn"
                type="button"
                onClick={ () => history.push('/algemene-voorwaarden') }
            >
                { t('general.terms') }
            </button>
            {' '}
            { t('general.and') }
            {' '}
            <button
                className="terms-btn"
                type="button"
                onClick={ () => history.push('/privacy-beleid') }
            >
                { t('general.privacy') }
            </button>
            {' '}
            .
        </div>
    );
};

export default Terms;

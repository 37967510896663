import { createContext } from 'react';
import { message as MessageType } from '../redux/types/chat';

type chatContextType = {
    messages: MessageType[];
    loading: boolean;
    loadingMessage: boolean;
    error: string;
    sendmessage?: (text: string) => void;
    fetchChat?: (activeChatPK: number, activeBubbleID: number) => void;
    clearChat?: () => void;
}

export const ChatContext = createContext<chatContextType>({
    messages: [],
    loading: false,
    loadingMessage: false,
    error: '',
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { bubble } from '../redux/types/user';

interface Props {
    sug: bubble;
    onClick: (id: number) => void;
}

const Suggestion: React.FC<Props> = ({ sug, onClick }) => {
    const { t } = useTranslation();

    const { id, name, user_count } = sug;

    return (
        <button
            onClick={ () => onClick(id) }
            className="focus:outline-none w-full text-left flex pl-6 py-3 text-lg"
            type="button"
        >
            <div className="flex-grow">
                { name }
            </div>
            <div className="pr-3 font-bold">
                { user_count }
                { ' ' }
                { t('bubbles.members') }
            </div>
        </button>
    );
};
export default Suggestion;

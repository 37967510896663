import { Dispatch, MiddlewareAPI } from 'redux';
import {
    BubbleTypes,
    searchBubbles as searchBubbleType,
} from '../types/bubble';

import {
    GET_COMPANY_BUBBLES,
    SEARCH_BUBBLES,
} from '../constants/bubble';

import UserService from '../../api/userService';

import {
    getCompanyFailed,
    getCompanySuccess,
    searchBubblesFailed,
    searchBubblesSuccess,
} from '../actions/bubble';

const getCompanyBubbles = async (store: MiddlewareAPI): Promise<void> => {
    try {
        const bubbles = await UserService.getCompanyBubbles();
        await store.dispatch(getCompanySuccess(bubbles));
    } catch (error) {
        await store.dispatch(getCompanyFailed(error.message));
    }
};

const searchBubble = async (store: MiddlewareAPI, action: searchBubbleType): Promise<void> => {
    try {
        const bubbles = await UserService.searchBubbles(action.query);
        await store.dispatch(searchBubblesSuccess(bubbles));
    } catch (error) {
        await store.dispatch(searchBubblesFailed(error.message));
    }
};

const bubbleMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: BubbleTypes): Promise<void> | BubbleTypes => {
    switch (action.type) {
        case GET_COMPANY_BUBBLES:
            next(action);
            return getCompanyBubbles(store);
        case SEARCH_BUBBLES:
            next(action);
            return searchBubble(store, action);
        default:
            return next(action);
    }
};

export default bubbleMiddleware;
